import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { Formik, useFormikContext } from "formik";
import InputText from "../../../components/common/InputText";
import { addSellerSchema } from "../../../validations/Franchise";
import { toast } from "react-toastify";
import { AddEntitiyMethod } from "../../../helpers/helpersMethods";
import Loader from "../../../components/common/Loader";
import {
  addSeller,
  updateFranchiesBlockStatus,
} from "../../../services/franchiseService";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { FaTimes } from "react-icons/fa";
import { categorySchema } from "../../../validations/Category";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { categoryAdd } from "../../../services/categoryService";
import { CgMenuGridR } from "react-icons/cg";

export const AddCategory = () => {
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const formik = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [status, setStatus] = useState("active");
  const [categoryTypeList, setCategoryTypeList] = useState([
    { id: "industry", name: "Industry" },
    { id: "document", name: "Document" },
  ]);
  const handleSubmit = (values, actions, setErrors) => {
    setFormSubmitted(true);
    actions.setSubmitting(false);

    if (values) {
      AddEntitiyMethod(
        setLoading,
        categoryAdd,
        values,
        "",
        nameBasedProtectedRoutes.category.path,
        navigate
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <CgMenuGridR size={40} />
                  </div>
                  Add Category
                </h5>
              </Stack>
            </div>
            <Formik
              validationSchema={categorySchema}
              initialValues={{
                name: "",
                description: "",
                status: "active",
                category_type: "",
              }}
              onSubmit={(values, actions) => {
                values.name = values.name.trim();
                values.description = values.description.trim();
                values.status = status;
                actions.setSubmitting(false);
                setFormSubmitted(true);
                handleSubmit(values, actions, actions.setErrors);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="flex-grow-1 d-flex flex-column px-1">
                    <div className="p-3 d-flex flex-column h-100">
                      <div className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                            <CustomSelect
                              controlId="category_type"
                              label="Category Type *"
                              placeholder="Select Category Type"
                              options={categoryTypeList}
                              touched={touched.category_type}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.category_type}
                              value={values.category_type}
                              name="category_type"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                            <InputText
                              controlId="name"
                              label="Category Name *"
                              type="text"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errorsField={errors.name}
                              touched={touched.name}
                              className="w-100 fs-16 mb-4 custom-text-field"
                              InputLabelProps={{
                                className: "fs-15 grey-color",
                              }}
                              value={values.name}
                              name="name"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                            <InputText
                              controlId="description"
                              label="Description *"
                              type="textarea"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errorsField={errors.description}
                              touched={touched.description}
                              className="w-100 fs-16 mb-4 custom-text-field customTextarea"
                              InputLabelProps={{
                                className: "fs-15 grey-color",
                              }}
                              value={values.description}
                              multiline
                              name="description"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                            Status
                            <SwitchCell
                              defaultChecked={values.status === "active"} // Assuming 'values.status' holds 'active' or 'inactive'
                              tooltip={
                                status == "active" ? "Active" : "Inactive"
                              }
                              onClick={() =>
                                setStatus((status) =>
                                  status == "active" ? "inactive" : "active"
                                )
                              }
                            />
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={2} className="my-3">
                          <Button
                            variant="primary"
                            className="rounded-lg fs-15 mw-84"
                            type="submit"
                          >
                            Save
                          </Button>
                          <Link
                            to={nameBasedProtectedRoutes.category.path}
                            className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                          >
                            Cancel
                          </Link>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>

      <Loader isLoading={loading} />
    </>
  );
};
