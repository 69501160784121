import { MainLayout } from "../components/layouts/MainLayout";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Profile } from "../pages/Profile/AddProfile";
import { ChangePassword } from "../pages/ChangePassword/ChangePassword";
import { Franchise } from "../pages/Franchise/routes";
import { Seller } from "../pages/Seller/routes";
import { User } from "../pages/User/routes";
import { Lead } from "../pages/Lead/routes";
import { Transactions } from "../pages/Payment/router";
import { Category } from "../pages/Category/routes";
import { Cms } from "../pages/Cms/routes";
import { Report } from "../pages/Report/router";

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
  {
    path: "/app",
    element: <MainLayout />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "franchise/*", element: <Franchise /> },
      { path: "seller/*", element: <Seller /> },
      { path: "leads/*", element: <Lead /> },
      { path: "users/*", element: <User /> },
      { path: "profile", element: <Profile /> },
      { path: "transaction/*", element: <Transactions /> },
      { path: "category/*", element: <Category /> },
      { path: "change-password", element: <ChangePassword /> },
      { path: "cms/*", element: <Cms /> },
      { path: "report/*", element: <Report /> },
    ],
  },
];
export const nameBasedProtectedRoutes = {
  userDashboard: {
    name: "Dashboard",
    path: `/`,
  },

  dashborad: {
    name: "Dashboard",
    path: `/app/dashboard`,
  },
  franchise: {
    name: "franchise",
    path: `/app/franchise`,
  },

  franchiseAdd: {
    name: "franchise",
    path: `/app/franchise/add`,
  },
  seller: {
    name: "seller",
    path: `/app/seller`,
  },

  profile: {
    name: "profile",
    path: `/app/profile`,
  },
  changePassword: {
    name: "change password",
    path: `/app/change-password`,
  },
  users: {
    name: "users",
    path: `/app/users`,
  },
  leads: {
    name: "leads",
    path: `/app/leads`,
  },
  transaction: {
    name: "Transactions",
    path: `/app/transaction`,
  },
  category: {
    name: "Category",
    path: `/app/category`,
  },
  categoryAdd: {
    name: "Category",
    path: `/app/category/add`,
  },
  cms: {
    name: "Cms",
    path: `/app/cms`,
  },
  report: {
    name: "Report",
    path: `/app/reports`,
  },
};
