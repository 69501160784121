import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CustomPagination = ({
  pageSize,
  setPageSize,
  rowCount,
  onPageChange,
  pageSizeOptions,
}) => {
    console.log(pageSize);
  if (!Array.isArray(pageSizeOptions) || pageSizeOptions.length === 0) {
    return null; // Return null if pageSizeOptions is not defined or empty
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <span>Rows per page:</span>
      <Select
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {pageSizeOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {/* Replace Pagination with your actual pagination component */}
    </div>
  );
};

export default CustomPagination;
