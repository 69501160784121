import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.svg";
import {
  getLeadDetails,
  getPaymentDetails,
} from "../../../services/franchiseService";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import CustomView from "../../../components/common/CustomView";
import { formatNumberWithTwoDecimals } from "../../../helpers/helpersMethods";

export const View = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getPaymentDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.transaction.path);
          }
        }
      );
    }
  }, [id]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Wallet_1_"
                      data-name="Wallet (1)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.5"
                      height="27.5"
                      viewBox="0 0 27.5 27.5"
                    >
                      <path
                        id="Path_28719"
                        data-name="Path 28719"
                        d="M65.548,4.176A2.689,2.689,0,0,1,68.233,1.49h.929l-.28-.779A1.073,1.073,0,0,0,67.5.065L58.113,3.483a1.074,1.074,0,0,0-.641,1.379l.5,1.354h7.58Z"
                        transform="translate(-54.322)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28720"
                        data-name="Path 28720"
                        d="M240.074,57.745A1.074,1.074,0,0,0,239,58.819V60.86h9.4V58.819a1.074,1.074,0,0,0-1.074-1.074Z"
                        transform="translate(-226.163 -54.643)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28721"
                        data-name="Path 28721"
                        d="M25.083,159.629h-3.76a4.028,4.028,0,0,1,0-8.057h3.76v-3.679a2.148,2.148,0,0,0-2.148-2.148H2.148A2.148,2.148,0,0,0,0,147.893v15.375a2.148,2.148,0,0,0,2.148,2.148H22.935a2.148,2.148,0,0,0,2.148-2.148Z"
                        transform="translate(0 -137.917)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28722"
                        data-name="Path 28722"
                        d="M359.788,284.245h-5.371a2.417,2.417,0,0,0,0,4.834h5.371a.807.807,0,0,0,.806-.806v-3.223A.807.807,0,0,0,359.788,284.245Zm-5.371,3.223a.806.806,0,1,1,.806-.806A.806.806,0,0,1,354.417,287.468Z"
                        transform="translate(-333.094 -268.978)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Transactions Details
                </h5>
                <Link
                  to={nameBasedProtectedRoutes.transaction.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={3} xl={3}>
                    <CustomView
                      label="Business / Franchise Name"
                      value={
                        data.franchise_business_type == "franchise"
                          ? data.franchise
                            ? data.franchise.name
                            : ""
                          : data.seller
                          ? data.seller.name
                          : ""
                      }
                      type="text"
                    />
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <CustomView
                      label="Type"
                      value={data.franchise_business_type}
                      type="text"
                      capital={true}
                    />
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <CustomView
                      label="Payment Date"
                      value={data.payment_date}
                      type="date"
                    />
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <CustomView
                      label="Renew Date"
                      value={data.renew_date}
                      type="date"
                    />
                  </Col>
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={3} xl={3}>
                    <CustomView
                      label="Amount($)"
                      value={
                        data.price > 0
                          ? formatNumberWithTwoDecimals(data.price / 100)
                          : data.price
                      }
                      type="text"
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Transaction ID"
                      value={data.transaction_id}
                      type="text"
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
