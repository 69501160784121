import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  MAXIMUM_200_CHAR,
  MAXIMUM_32_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_8_CHAR,
  NAME_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  NUMBER_ONLY,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  REQUIRED,
  // USER_NAME_REQUIRED,
  VALID_EMAIL,
  YEAR_ONLY,
} from "./ValidationErrors";




export const addFranchiseSchema = yup.object().shape({
  name: yup.string().trim().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  founder_name: yup.string().trim().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  franchise_since: yup.string().trim().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  descrption: yup.string().trim().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  address: yup.string().trim().min(3, MINIMUM_3_CHAR).max(200,MAXIMUM_200_CHAR).required(REQUIRED),
  total_units: yup.string().trim().matches(/^\d{1,5}$/,NUMBER_ONLY).required(REQUIRED),
  verterned_own_unit: yup.string().trim().matches(/^\d{1,5}$/,NUMBER_ONLY).required(REQUIRED),
  employees: yup.string().trim().matches(/^\d{1,5}$/,NUMBER_ONLY).required(REQUIRED),
  year_founded: yup.string().trim().matches(/^\d{4}$/,YEAR_ONLY).required(REQUIRED),
  annual_revenue: yup.string().trim().required(REQUIRED),
  net_worth: yup.string().trim().required(REQUIRED),
  country_id: yup.string().trim().required(REQUIRED),
  city_id: yup.string().trim().required(REQUIRED),
  state_id: yup.string().trim().required(REQUIRED)
});



