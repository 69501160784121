import React, { useEffect } from "react";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { AuthHeader } from "../authHeader/AuthHeader";

import { LoginSchema } from "../../../validations/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "universal-cookie";

import { useState } from "react";
import Loader from "../../../components/common/Loader";
import storage from "../../../helpers/storage";
import { toast } from "react-toastify";
import { login } from "../../../services/authService";
import { nameBasedRoutes } from "../../../router/public";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { MdMail, MdKey } from "react-icons/md";
import { Box } from "@mui/material";
import InputText from "../../../components/common/InputText";

export const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  // data
  const [data, setData] = useState({
    email: "",
    password: "",
    captcha: "",
  });

  // remember me
  const rememberMeEmail = cookies.get("admin_email");
  const rememberMePassword = cookies.get("admin_password");
  const alreadyChecked = cookies.get("admin_checked");
  const [rememberMe, setRemembeMe] = useState(!!alreadyChecked);

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [showEye, setShowEye] = useState(false);
  const initialValues = {
    email: rememberMeEmail ? rememberMeEmail : "",
    password: rememberMePassword ? rememberMePassword : "",
    captcha: "",
    rememberCheck: false,
  };

  // For remember details
  useEffect(() => {
    if (rememberMeEmail && rememberMePassword) {
      setData((data) => ({ ...data, email: rememberMeEmail }));
      setData((data) => ({ ...data, password: rememberMePassword }));
      setTimeout(() => {}, 1000);
      setLoading(true);
    }
    setLoading(false);
  }, []);

  // to restricet admin only
  useEffect(() => {
    if (storage.getToken() != null) {
      const userInfo = storage.getData("admin-user-info");
      return navigate(nameBasedProtectedRoutes.dashborad.path);
    }
  }, [navigate]);

  /**
   * Submit Form
   * @date 12/04/2023 - 13:32:30
   *
   * @async
   * @param {string} email
   * @param {string} password
   * @param {*} captcha
   * @param {*} actions
   * @returns {*}
   */
  const handleSubmit = async (email, password, captcha, actions) => {
    setLoading(true);

    if (rememberMe === false) {
      cookies.remove("admin_email");
      cookies.remove("admin_password");
      cookies.remove("admin_checked");
    }
    login({ email, password })
      .then((response) => {
        setLoading(false);
        cookies.set("page_heading", "Dashboard"); // page heading
        if (rememberMe === true) {
          cookies.set("admin_email", email);
          cookies.set("admin_password", password);
          cookies.set("admin_checked", rememberMe);
        }
        setLoading(false);
        storage.setToken(response.data.auth_token);
        storage.setData("admin-user-info", response.data);
        storage.setPageTitle("title", "Dashboard");

        toast.success(response.data.msg);
        navigate(nameBasedProtectedRoutes.dashborad.path);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data.msg);
        return;
        setTimeout(() => {}, 1000);
      });
  };

  const showToggle = (event) => {
    setShowEye(event);
  };
  return (
    <>
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values.email, values.password, values.captcha, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <div className="d-flex p-3 min-vh-100 w-100 theme-body-bg align-items-center">
            <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
              <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100 shadow-sm">
                <div className="fw-normal text-center mb-3">
                  <AuthHeader />
                </div>
                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <MdMail
                    className={`inputIco ${
                      values.email.trim() !== "" ? "filled" : ""
                    }`}
                    size="15"
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <InputText
                    controlId="email"
                    label="Email Address *"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.email}
                    touched={touched.email}
                    className="w-100 fs-16 mb-3 custom-text-field"
                    InputLabelProps={{
                      className: "fs-15 grey-color",
                    }}
                    value={values.email}
                    name="email"
                  />
                </Box>

                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <div
                    className={`position-relative password-field ${
                      showEye ? "form-right-icon" : ""
                    }`}
                  >
                    <MdKey
                      className={`inputIco ${
                        values.password.trim() !== "" ? "filled" : ""
                      }`}
                      size="15"
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <InputText
                      controlId="password"
                      label="Password *"
                      type={showEye ? "text" : "password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorsField={errors.password}
                      touched={touched.password}
                      value={values.password}
                      name="password"
                      className="w-100 fs-16 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color",
                      }}
                    />

                    <div
                      className="credEye"
                      onClick={() => setShowEye(!showEye)}
                    >
                      {showEye ? (
                        <FaEye
                          size="22"
                          className="eyePosition light-grey"
                          onClick={() => showToggle(true)}
                        />
                      ) : (
                        <FaEyeSlash
                          size="22"
                          className="eyePosition light-grey"
                          onClick={() => showToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                </Box>
                <Form.Group className="mt-4 pt-2 mb-3">
                  <Row
                    xs="auto"
                    className="align-items-center justify-content-between gx-0"
                  >
                    <Col>
                      <Form.Check
                        id="rememberCheck"
                        className="fs-14 lh-lg mh-auto mb-0 user-select-none customCheck"
                        type="checkbox"
                        label="Remember me"
                        checked={rememberMe}
                        onChange={(e) => setRemembeMe(e.target.checked)}
                        //checked={isChecked}
                        //onChange={onChangeCheckbox}
                      />
                    </Col>
                    <Col>
                      <Link
                        className="fs-14 text-decoration-none fw-bold"
                        to={nameBasedRoutes.forgotPassword.path}
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                </Form.Group>
                <Button
                  className="fw-bold fs-18 text-white min-h-48 mt-2 w-100"
                  variant="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Log In
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <Loader isLoading={loading} />
    </>
  );
};
