import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllSeller } from "./List/List";
import { View } from "./View/View";

export const Seller = () => {
  storage.setPageTitle("Business Seller");
  return (
    <Routes>
      <Route path="/" element={<AllSeller />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
