import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AddCategory } from "./Add/Add";
import { EditCategory } from "./Edit/Edit";
import { AllCategory } from "./List/List";
import { View } from "./View/View";

export const Category = () => {
  storage.setPageTitle("Category");
  return (
    <Routes>
      <Route path="/" element={<AllCategory />} />
      <Route path="/add" element={<AddCategory />} />
      <Route path="/edit/:id" element={<EditCategory />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
