import * as yup from "yup";
import {
  MAXIMUM_15000_CHAR,
  MAXIMUM_500_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_3_CHAR,
  REQUIRED,
} from "./ValidationErrors";

export const categorySchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  description: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required(REQUIRED),
  status: yup.string().trim().required(REQUIRED),
  category_type: yup.string().trim().required(REQUIRED),
});
export const cmsSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  description: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(15000, MAXIMUM_15000_CHAR)
    .required(REQUIRED),
});
