import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { MdHandshake, MdSupervisorAccount } from "react-icons/md";
import { MatDataGrid } from "../../../components/common/dataGrid/DataGrid";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getUserListAPI,
  changeUserStatus,
  deleteUser,
  getUserList,
  updateUserBlockStatus,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import {
  franchiseDelete,
  getFranchiseList,
  getSellerList,
  sellerDelete,
  updateSellerBlockStatus,
} from "../../../services/franchiseService";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/common/dataGrid/CustomPagination";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  AddEntitiyMethod,
  AddPaymentEntitiyMethod,
  formatNumberWithTwoDecimals,
  truncateText,
} from "../../../helpers/helpersMethods";
import commonValue from "../../../constants/commonValue";
import moment from "moment";
import { styled } from "@mui/material/styles";
import storage from "../../../helpers/storage";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#047582",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllUser = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (props) =>
        props.row.name ? truncateText(props.row.name) : "-",
    },
    {
      field: "email",
      headerName: "Email Address",
      editable: false,
      flex: 0.6,
      minWidth: 80,
      renderCell: (props) => props.row.email,
    },
    {
      field: "source",
      headerName: "User Type",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 150,
      renderCell: (props) =>
        props.row.source == "app_users" ? "App User" : "Business",
    },
    {
      field: "created_at",
      headerName: "Registration Date",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      renderCell: (props) =>
        moment(props.row.created_at).format(
          commonValue.commaSeparatedShowDateTime
        ),
    },

    {
      field: "is_admin_block",
      headerName: "Status",
      editable: false,
      flex: 0.3,
      minWidth: 130,
      sortable: false,
      renderCell: (props) => {
        return (
          <SwitchCell
            defaultChecked={
              props.row.is_admin_block == "yes" ? "" : props.row.is_admin_block
            }
            tooltip={props.row.is_admin_block == "yes" ? "Inactive" : "Active"}
            onClick={() =>
              statusToggle(
                props.row.user_id,
                props.row.is_admin_block,
                props.row.source
              )
            }
          />
        );
      },
    },
  ];

  // Searching and listing user list
  const handleValueChange = (value) => {
    setSelectedValue(value);
    setCurrentPage(1);
    userList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      ...(value ? { searchtext: value } : { searchtext: "" }),
    });
  };

  // Get user list details
  const userList = async (params) => {
    setLoading(true);
    try {
      
      const response = await getUserList({ ...params });
      const res = response?.data;
      const itemsPerPage = params?.limit || 10;
      const currentPage = params?.page || 1;
      const startIndex = (currentPage - 1) * itemsPerPage + 1;
      setData([]);
      const dataWithSNo = res.results.map((item, index) => ({
        ...item,
        sNo: startIndex + index,
      }));

      setLoading(false);
      setData(dataWithSNo);
      console.log(dataWithSNo); // Use dataWithSNo instead of data
      setTotalPages(res.totalPages);
      setTotalResults(res.totalResults);
      setLoading(false);
    } catch (error) {
      const err = error?.response?.data;
      setLoading(false);
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    }
  };

  // On page load fetch user list
  useEffect(() => {
    userList({
      page: currentPage,
      limit: rowPerPage,
      searchtext: selectedValue,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  }, [page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };

  const statusToggle = (id, value, type) => {
    toast.dismiss();
    const status = value == "yes" ? "no" : "yes";
    const changeStatusData = { id: id, is_admin_block: status, type: type };
    if (id) {
      AddEntitiyMethod(
        setLoading,
        updateUserBlockStatus,
        changeStatusData,
        "",
        nameBasedProtectedRoutes.users.path,
        navigate
      );
      setTimeout(() => {
        setLoading(false);
        userList({
          page: currentPage,
          limit: rowPerPage,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          searchtext: selectedValue,
        });
      }, 1000);
    }
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <MdSupervisorAccount size={40} />
                  </div>
                  User List
                </h5>

                {/* <Button variant="primary">Add Franchise</Button> */}
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        className="custom-text-field"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100 dataGridMedia">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "";
                      const sortDirection = model[0]?.sort || "";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
    </>
  );
};
