export default {
  fileSize: {
    profileImage: {
      min: 10,
      max: 500,
    },
  },
  MaxLength: 25,
  commaSeparatedDateTime: "MM/DD/YYYY",
  commaSeparatedShowDateTime: "DD/MM/YYYY",
  dashseparatedDateTime: "DD-MM-YYYY",
};
