import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import CustomView from "../../../components/common/CustomView";
import { getCmsDetails } from "../../../services/cmsService";

export const View = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getCmsDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.category.path);
          }
        }
      );
    }
  }, [id]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.62"
                      height="24.62"
                      viewBox="0 0 24.62 24.62"
                    >
                      <g
                        id="Architecture_and_city"
                        data-name="Architecture and city"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_28725"
                          data-name="Path 28725"
                          d="M0,165.714a2.648,2.648,0,0,0,2.645,2.645H21.975a2.648,2.648,0,0,0,2.645-2.645V151H0Zm14.474-2.453H10.146a1.443,1.443,0,1,1,0-2.885h4.328a1.443,1.443,0,1,1,0,2.885Zm-6.492-9.377h8.655a.721.721,0,1,1,0,1.443H7.982a.721.721,0,1,1,0-1.443ZM5.1,156.77H19.523a.721.721,0,1,1,0,1.443H5.1a.721.721,0,1,1,0-1.443Zm0,0"
                          transform="translate(0 -143.739)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28726"
                          data-name="Path 28726"
                          d="M21.975,0H2.644A2.648,2.648,0,0,0,0,2.645V5.818H24.62V2.645A2.648,2.648,0,0,0,21.975,0Zm-6.78,4.376a.721.721,0,1,1,.721-.721A.721.721,0,0,1,15.195,4.376Zm2.885,0a.721.721,0,1,1,.721-.721A.721.721,0,0,1,18.08,4.376Zm2.885,0a.721.721,0,1,1,.721-.721A.721.721,0,0,1,20.965,4.376Zm0,0"
                          transform="translate(0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </div>
                  CMS Pages Details
                </h5>

                <Link
                  to={nameBasedProtectedRoutes.cms.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={3}>
                    <CustomView
                      label="Title"
                      value={data.title}
                      type="text"
                      capital={true}
                    />
                  </Col>
                  {/* <Col sm={6} lg={4} xl={3}>
                    <CustomView
                      label="Type"
                      value={data.category_type}
                      type="text"
                      capital={true}
                    />
                  </Col> */}
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Description"
                      value=" "
                      type="text"
                      capital={true}
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    ></div>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
