export const USER_NAME_REQUIRED = "Username is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CURRENT_PASSWORD_REQUIRED = "Current Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm new password is required.";
export const MINIMUM_15_CHAR = "Must be a minimum of 15 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 30 characters.";
export const PASSWORD_REGEX =
  "Must contain 1 Capital letter, 1 lower case letter,  1 number, 1 special characters With No Blank Space";
export const PASSWORD_MUST_MATCH =
  "Confirm password must match with new password.";
export const VALID_EMAIL = "Email must be a valid email.";
export const SITE_CODE_REQUIRED = "Site code is required.";
export const SITE_NAME_REQUIRED = "Site name is required.";
export const CONTACT_FIRST_NAME_REQUIRED = "Person first name is required.";
export const CONTACT_LAST_NAME_REQUIRED = "Person last name is required.";
export const PHONE_NO_REQUIRED = "Phone no is required.";
export const CHAR_MAX_10 = "Can exeed upto maximum 10 characters.";
export const CHAR_MAX_20 = "Can exeed upto maximum 20 characters.";
export const CHAR_MAX_50 = "Can exeed upto maximum 50 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const PHONE_NUM_LENGTH = "Phone no should have 10 characters.";
export const EMPLOYEE_CODE_REQUIRED = "Employee code is required.";
export const SITES_CODE_REQUIRED = "Sites selection is required.";
export const REQUIRED = "Field is Required.";
export const NUMERIC_10 = "Should be less than 10";
export const NUMERIC_PERCENTAGE = "Should be valid percentage";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const MINIMUM_8_CHAR = "Must be a minimum of 8 characters.";
export const MAXIMUM_32_CHAR = "Can not be a maximum than 32 characters.";
export const MINIMUM_2_CHAR = "Must be a minimum of 2 characters.";
export const MINIMUM_3_CHAR = "Must be a minimum of 3 characters.";
export const MAXIMUM_50_CHAR = "Can not be a maximum than 50 characters.";
export const MAXIMUM_200_CHAR = "Can not be a maximum than 200 characters.";
export const NAME_REQUIRED = "Name is required.";
export const NEW_PASSWORD_REQUIRED = "New password is required.";
export const CHANGE_PASSWORD_SUCCESS = "Change password successful";
export const NUMBER_ONLY = "Please enter valid number and allow max 5 digit";
export const YEAR_ONLY = "Please enter valid year Ex-2022";
export const MAXIMUM_500_CHAR = "Can not be a maximum than 500 characters.";
export const MAXIMUM_15000_CHAR = "Can not be a maximum than 15000 characters.";
