import { axios } from "./axios";
import {
  FORGOT_API,
  LOGIN_API,
  LOGOUT,
  REFRESH_TOKEN,
  REGISTER,
  RESET_API,
  VERIFICATION,
  VERIFICATION_RESET_TOKEN,
} from "../constants/paths";

/**
 * Login API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */
export const login = (data) => {
  return axios.post(LOGIN_API, data);
};

/**
 * Forgot Password API Call
 * @param  data
 * @returns {Promise<any>}
 */
export const forgotPassword = (data) => {
  return axios.post(FORGOT_API, data);
};

/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */
export const resetPassword = (data, token) => {
  console.log("token", token);
  return axios.post(RESET_API, data);
};

/**
 * Logout API Call
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const logout = (data) => {
  return axios.post(LOGOUT, data);
};

/**
 * Verify reset token
 * @date 11/07/2023 - 18:07:01
 *
 * @param {*} data
 * @returns {*}
 */
export const resetPasswordToken = (data) => {
  return axios.post(VERIFICATION_RESET_TOKEN, data);
};

/**
 * Refresh Token API
 *
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const refreshTokenCALL = (data) => {
  return axios.post(REFRESH_TOKEN, data);
};

/**
 * Register
 * @date 12/07/2023 - 18:45:27
 *
 * @async
 * @param {*} data
 * @returns {unknown}
 */
export const userRegister = async (data) => {
  return axios.post(REGISTER, data);
};
export const userVerify = async (token) => {
  return axios.get(VERIFICATION + "?token=" + token);
};
