import { Formik } from "formik";
import { Button, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { ResetPasswordSchema } from "../../../validations/Auth";
import { AuthHeader } from "../authHeader/AuthHeader";
import { useEffect, useState } from "react";
import { FaExclamationTriangle, FaEye, FaEyeSlash } from "react-icons/fa";

import { MdKey } from "react-icons/md";
import { Box } from "@mui/material";
import Cookies from "universal-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  resetPassword,
  resetPasswordToken,
} from "../../../services/authService";
import { nameBasedRoutes } from "../../../router/public";
import InputText from "../../../components/common/InputText";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

export const ResetPassword = () => {
  const [showEye, setShowEye] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let token = "";
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [showConfirmPasEye, setShowConfirmPasEye] = useState(false);
  const [tokens, setTokens] = useState(false);
  const showToggle = (event) => {
    setShowEye(event);
  };
  // Show/hide confirm password input field
  const onConfirmPasswordShowToggle = (event) => {
    setShowConfirmPasEye(event);
  };

  const search = useLocation().search;
  useEffect(() => {
    setLoading(true);
    token = new URLSearchParams(search).get("token");
    resetPasswordToken({ token: token }).then(
      (response) => {
        setLoading(false);
        const res = response?.data;
        if (response?.statusCode === 200) {
        }
      },
      (error) => {
        toast.dismiss();
        setLoading(false);
        toast.error(error.response.data.msg);
        setTokenExpired(true);
      }
    );
    if (!token) {
      navigate(nameBasedRoutes.home.path);
    }
  }, [tokens]);
  const handleSubmit = (values, actions) => {
    setLoading(true);
    toast.dismiss();
    token = new URLSearchParams(search).get("token");
    resetPassword({
      password: values.password,
      token: token ? token : "",
    }).then(
      (response) => {
        setLoading(false);
        const res = response?.data;
        if (response?.statusCode === 200) {
          toast.success(response.msg);
          cookies.remove("admin_password");
          navigate(nameBasedRoutes.home.path);
        }
      },
      (error) => {
        toast.dismiss();
        setLoading(false);
        toast.error(error.response.msg);
      }
    );
  };

  return (
    <>
      {!tokenExpired ? (
        <Formik
          validationSchema={ResetPasswordSchema}
          onSubmit={handleSubmit}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <div className="d-flex p-3 min-vh-100 w-100 theme-body-bg align-items-center">
              <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
                <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100 shadow-sm pt-40 position-relative">
                  <Form className="p-2" noValidate onSubmit={handleSubmit}>
                    <div className="fw-normal text-center mb-3">
                      <AuthHeader />
                    </div>
                    <h6 className="fw-normal text-center mb-3 h5 fw-bold heading">
                      Reset Password
                    </h6>
                    <p className="fw-normal text-center mb-3 fs-14 mw-290 m-auto lh-sm subheading">
                      Now you can create new password.
                    </p>

                    <Box className="credCoverB" sx={{ display: "flex" }}>
                      <div
                        className={`position-relative password-field ${
                          showEye ? "form-right-icon mb-3" : ""
                        }`}
                      >
                        <MdKey
                          className={`inputIco ${
                            values.password.trim() !== "" ? "filled" : ""
                          }`}
                          size="15"
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <InputText
                          controlId="password"
                          label="Password *"
                          type={showEye ? "text" : "password"}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errorsField={errors.password}
                          touched={touched.password}
                          value={values.password}
                          name="password"
                          className="w-100 fs-16 custom-text-field mb-3"
                          InputLabelProps={{
                            className: "fs-15 grey-color",
                          }}
                        />

                        <div
                          className="credEye"
                          onClick={() => setShowEye(!showEye)}
                        >
                          {showEye ? (
                            <FaEye
                              size="22"
                              className="eyePosition light-grey"
                              onClick={() => showToggle(true)}
                            />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition light-grey"
                              onClick={() => showToggle(true)}
                            />
                          )}
                        </div>
                      </div>
                    </Box>

                    <Box className="credCoverB" sx={{ display: "flex" }}>
                      <div
                        className={`position-relative password-field ${
                          showEye ? "form-right-icon" : ""
                        }`}
                      >
                        <MdKey
                          className={`inputIco ${
                            values.confirmPassword.trim() !== "" ? "filled" : ""
                          }`}
                          size="15"
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <InputText
                          controlId="confirmPassword"
                          label="Confirm Password *"
                          type={showConfirmPasEye ? "text" : "password"}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errorsField={errors.confirmPassword}
                          touched={touched.confirmPassword}
                          value={values.confirmPassword}
                          name="confirmPassword"
                          className="w-100 fs-16 custom-text-field mb-4"
                          InputLabelProps={{
                            className: "fs-15 grey-color",
                          }}
                        />

                        <div
                          className="credEye"
                          onClick={() =>
                            setShowConfirmPasEye(!showConfirmPasEye)
                          }
                        >
                          {showConfirmPasEye ? (
                            <FaEye
                              size="22"
                              className="eyePosition light-grey"
                              onClick={() => onConfirmPasswordShowToggle(true)}
                            />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition light-grey"
                              onClick={() => onConfirmPasswordShowToggle(true)}
                            />
                          )}
                        </div>
                      </div>
                    </Box>
                    <Stack gap={4} className="justify-content-center">
                      <Button
                        className="fw-semibold fs-18 text-white min-h-48 mt-2 mb-3 w-100"
                        variant="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="min-vh-100 w-100 d-flex flex-column p-3">
          <div className="col-lg-7 m-auto text-center">
            <FaExclamationTriangle size={40} color="red" />
            <h1 className="fs-3 fw-semibold my-3">
              Oops! Your Reset Password Link expired
            </h1>
            <p className="fs-16 text-muted">
              Please request a new password to continue reset your password.
            </p>
            <div className="d-inline-flex gap-2 mb-5">
              <Link
                to={nameBasedRoutes.home.path}
                className="btn btn-primary fw-semibold fs-13 text-white mw-90 mt-2"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      )}
      <Loader isLoading={loading} />
    </>
  );
};
