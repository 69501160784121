import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllReports } from "./List/List";;
export const Report = () => {
  storage.setPageTitle("Reports and Stats");
  return (
    <Routes>
      <Route path="/" element={<AllReports />} />
    </Routes>
  );
};
