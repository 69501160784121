import React from "react";
import Switch from "@mui/material/Switch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const label = { inputProps: { "aria-label": "Switch demo" } };

export const SwitchCell = (props) => {
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="view-tooltip">
            {props.tooltip ? props.tooltip : "status"}
          </Tooltip>
        }
      >
        <Switch className="customSwitch" {...props} />
      </OverlayTrigger>
    </>
  );
};
