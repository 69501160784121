import React, { useEffect, useState } from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getUserListAPI,
  changeUserStatus,
  deleteUser,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import {
  getLeadList,
  getPaymentList,
  updateLeadStatus,
} from "../../../services/franchiseService";
import { DataGrid,GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import CustomPagination from "../../../components/common/dataGrid/CustomPagination";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";

import {
  CapitalText,
  formatNumberWithTwoDecimals,
  truncateText,
} from "../../../helpers/helpersMethods";
import commonValue from "../../../constants/commonValue";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { SelectCell } from "../../../components/common/SelectCell";
import FormDatesPicker from "../../../components/common/FormDatesPicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MdClose } from "react-icons/md";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#047582",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllTransactions = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);

  const [show, setShow] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectRow, setSelectRow] = useState("");
  const [selectDate, setSelectDate] = useState(null);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fields: ['sNo', 'name','email','franchise_business_type','payment_date','renew_date','price','paymentmethod_id'] }} />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (selectValue) {
      const requestData = {
        id: selectRow,
        status: selectValue,
      };

      setLoading(true); // Show loader during API call

      // Make the API call to update the status
      updateLeadStatus(requestData).then(
        (response) => {
          const res = response?.data;
          setTimeout(() => {
            setLoading(false);
            if (response?.statusCode === 200) {
              // Update the data in your data grid
              const updatedData = data.map((item) => {
                if (item.id === selectRow) {
                  return { ...item, status: selectValue };
                }
                return item;
              });
              setData(updatedData);
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          // Handle error and show a notification
          toast.error(error.response.data.msg);
        }
      );
    }
  }, [selectValue, selectRow]);

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      sortable: false,
      flex: 0.05,
      minWidth: 80,
    },
    {
      field: "name",
      headerName: "Business / Franchise Name",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      renderCell: (props) =>
        //props.row.seller.name
        truncateText(
          props.row.franchise_business_type == "franchise"
            ? props.row.franchise.name
            : (props.row?.seller?.name)
      ),
      valueGetter: (props) => `${props.row.franchise_business_type == "franchise"
      ? props.row.franchise.name
      : props.row?.seller?.name}`
    },
    {
      field: "email",
      headerName: "User Email",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 400,

      renderCell: (props) =>
        //props.row.seller.name
        truncateText(
          props.row.franchise_business_type == "franchise"
            ? props.row?.franchise?.user?.email
            : props.row?.seller?.user?.email
        ),
      valueGetter: (props) => `${props.row.franchise_business_type == "franchise"
      ? props.row.franchise.user.email
      : props?.row?.seller?.user.email}`
    },

    {
      field: "franchise_business_type",
      headerName: "Type",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 300,
      renderCell: (props) =>
        truncateText(CapitalText(props.row.franchise_business_type)),
      valueGetter: (props) => `${props.row.franchise_business_type}`
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      renderCell: (props) =>
        moment(props.row.payment_date).format(
          commonValue.commaSeparatedShowDateTime
      ),
      valueGetter: (props) => `${moment(props.row.payment_date).format(
        commonValue.commaSeparatedShowDateTime
      )}`
    },
    {
      field: "renew_date",
      headerName: "Renew Date",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      renderCell: (props) =>
        moment(props.row.renew_date).format(
          commonValue.commaSeparatedShowDateTime
      ),
      valueGetter: (props) => `${moment(props.row.renew_date).format(
        commonValue.commaSeparatedShowDateTime
      )}`
    },

    {
      field: "price",
      headerName: "Amount($)",
      editable: false,
      flex: 0.6,
      minWidth: 50,
      renderCell: (props) => formatNumberWithTwoDecimals(props.row.price / 100),
      valueGetter: (props) => `${formatNumberWithTwoDecimals(props.row.price / 100)}`
    },
    {
      field: "paymentmethod_id",
      headerName: "Transactions Id",
      editable: false,
      flex: 0.6,
      minWidth: 250,
      maxWidth: 300,
      sortable: false,
      renderCell: (props) => props.row.paymentmethod_id,
      valueGetter: (props) => `${props.row.paymentmethod_id}`
    },

    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 80,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/transaction"
            isViewEnable={true}
            isEditEnable={false}
            isDeleteEnable={false}
            redirectID={props.row.id}
            rowData={props.row}
          />
        );
      },
    },
  ];

  // Searching and listing user list
  // const handleValueChange = (value, type) => {
  //   setSelectedValue(value);
  // };

  useEffect(() => {
    setCurrentPage(1);
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchtext: selectedValue,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [selectedValue, selectDate]);

  // Get user list details
  const fetchList = (params) => {
    toast.dismiss();
    setLoading(true);
    getPaymentList({ ...params }).then(
      (response) => {
        const res = response?.data;
        const itemsPerPage = params?.limit || 20;
        const currentPage = params?.page || 1;
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        setData([]);
        const dataWithSNo = res.record.map((item, index) => ({
          ...item,
          sNo: startIndex + index,
        }));
        setLoading(false);
        setData(dataWithSNo);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        setLoading(false);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  };

  // On page load fetch user list
  useEffect(() => {
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      searchtext: selectedValue,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };
  //change status
  const handleSelectRow = (id, value) => {
    setSelectRow(id);
    setSelectValue(value);
  };
  const handleClear = () => {
    setSelectDate(null);
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Wallet_1_"
                      data-name="Wallet (1)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.5"
                      height="27.5"
                      viewBox="0 0 27.5 27.5"
                    >
                      <path
                        id="Path_28719"
                        data-name="Path 28719"
                        d="M65.548,4.176A2.689,2.689,0,0,1,68.233,1.49h.929l-.28-.779A1.073,1.073,0,0,0,67.5.065L58.113,3.483a1.074,1.074,0,0,0-.641,1.379l.5,1.354h7.58Z"
                        transform="translate(-54.322)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28720"
                        data-name="Path 28720"
                        d="M240.074,57.745A1.074,1.074,0,0,0,239,58.819V60.86h9.4V58.819a1.074,1.074,0,0,0-1.074-1.074Z"
                        transform="translate(-226.163 -54.643)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28721"
                        data-name="Path 28721"
                        d="M25.083,159.629h-3.76a4.028,4.028,0,0,1,0-8.057h3.76v-3.679a2.148,2.148,0,0,0-2.148-2.148H2.148A2.148,2.148,0,0,0,0,147.893v15.375a2.148,2.148,0,0,0,2.148,2.148H22.935a2.148,2.148,0,0,0,2.148-2.148Z"
                        transform="translate(0 -137.917)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28722"
                        data-name="Path 28722"
                        d="M359.788,284.245h-5.371a2.417,2.417,0,0,0,0,4.834h5.371a.807.807,0,0,0,.806-.806v-3.223A.807.807,0,0,0,359.788,284.245Zm-5.371,3.223a.806.806,0,1,1,.806-.806A.806.806,0,0,1,354.417,287.468Z"
                        transform="translate(-333.094 -268.978)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Transactions List
                </h5>
                {/* <Link
                  to={nameBasedProtectedRoutes.sellerAdd.path}
                  className="btn btn-primary"
                >
                  Add New
                </Link> */}

                {/* <Button variant="primary">Add Franchise</Button> */}
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 dateTopPanel position-relative"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {selectDate && (
                          <div
                            className="close-icon-calendar"
                            onClick={handleClear}
                          >
                            <MdClose size={21} color="#7c7a7a" />
                          </div>
                        )}
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Select Date"
                            className="custom-datepicker custom-text-field"
                            value={selectDate}
                            onChange={(e) => {
                              setSelectDate(e);
                            }}
                            maxDate={dayjs().subtract(0, "day")}
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Col>
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 d-flex align-items-end"
                    >
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        className="custom-text-field"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          setSelectedValue(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: CustomToolbar,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "";
                      const sortDirection = model[0]?.sort || "";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                      // fetchList({
                      //   page,
                      //   limit: rowPerPage,
                      //   searchtext: selectedValue,
                      //   sortColumn: sortColumn,
                      //   sortDirection: sortDirection,
                      // });
                    }}
                    pageSizeOptions={[20, 50, 75, 100]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
    </>
  );
};
