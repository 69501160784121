import { Image } from 'react-bootstrap'
import Logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';


export const AuthHeader = () => {
    return (
        <>
            <h1 className="fw-normal h2 mb-2 pb-2 logoCr"><Link to="/"><Image className="img-fluid text-blue" src={Logo} alt="Logo" width={184} height={62} /></Link></h1>
        </>
    )
}
