import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.svg";
import { getFranchiseDetails } from "../../../services/franchiseService";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import { formatNumberWithTwoDecimals } from "../../../helpers/helpersMethods";
import moment from "moment";
import commonValue from "../../../constants/commonValue";

export const View = () => {
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailsDataTwo, setDetailsDataTwo] = useState({
    part1: [],
    part2: [],
  });
  const [awardData, setAwardData] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getFranchiseDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  }, [id]);
  useEffect(() => {
    setAwardData(data.franchise_award);
    setLinkData(data.franchise_link);
    setDocumentData(data.franchise_document);
    setMediaData(data.franchise_media);
    setDetailsDataTwo({
      part1: [
        {
          title: "Total Units",
          detail: data.total_units,
        },
        {
          title: "Year Founded",
          detail: data.year_founded,
        },
        {
          title: "Founder Name",
          detail: data.founder_name,
        },
        {
          title: "Franchise Since",
          detail: data.franchise_since,
        },
      ],
      part2: [
        {
          title: "Verterned Own Unit",
          detail: data.verterned_own_unit,
        },
        {
          title: "Employees",
          detail: data.employees,
        },
        {
          title: "Min. Investment",
          detail: formatNumberWithTwoDecimals(data.annual_revenue),
        },
        {
          title: "Net Worth",
          detail: formatNumberWithTwoDecimals(data.net_worth),
        },
      ],
    });
  }, [data]);
  const handleDownload = (documentFileName) => {
    // Replace 'BASE_URL' with the actual base URL where your files are located.
    const fileUrl = documentFileName;

    // Open a new window with the file URL to trigger the download.
    window.open(fileUrl, "_blank");
  };
  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Franchise_3_"
                      data-name="Franchise (3)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Path_28704"
                        data-name="Path 28704"
                        d="M49.758,320.457h9.55v1.758h1.758v-1.758h9.551v1.758h1.758v-2.637a.879.879,0,0,0-.879-.879H61.066V317H59.308v1.7H48.879a.879.879,0,0,0-.879.879v2.637h1.758Z"
                        transform="translate(-45.188 -298.426)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28705"
                        data-name="Path 28705"
                        d="M392.5,436h-5.625a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H392.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,392.5,436Z"
                        transform="translate(-363.383 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28706"
                        data-name="Path 28706"
                        d="M6.5,436H.879a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H6.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,6.5,436Z"
                        transform="translate(0 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28707"
                        data-name="Path 28707"
                        d="M199.495,436H193.87a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879h5.625a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,199.495,436Z"
                        transform="translate(-181.683 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28708"
                        data-name="Path 28708"
                        d="M117.862,1.758h.059V16.816h4.043V11.426a.879.879,0,0,1,.879-.879h4.571a.879.879,0,0,1,.879.879v5.391h4.043V1.758h.059a.879.879,0,0,0,0-1.758H117.862a.879.879,0,0,0,0,1.758Zm4.453,1.758h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Zm0,3.516h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Z"
                        transform="translate(-110.129)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28709"
                        data-name="Path 28709"
                        d="M231.983,210H234.8v4.512h-2.814Z"
                        transform="translate(-218.39 -197.695)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Franchise Details
                </h5>
                <Link
                  to={nameBasedProtectedRoutes.franchise.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={3} xl={3}>
                    <div className="d-flex">
                      <div className="detailSideCol">
                        <span>
                          <Image src={data.logo ? data.logo_link : Logo} />
                        </span>
                      </div>
                      <Form.Group className="pb-1 lh-sm">
                        <Form.Label className="mb-1 fw-normal labelColor fs-13">
                          Franchise Name
                        </Form.Label>
                        <div className="static-data fs-16">
                          {data.name ? data.name : "-"}
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Subscription Plan / Renew Date
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.franchise_subscription
                          ? data.franchise_subscription.subscription.name
                          : "-"}

                        {data.is_auto_renew == "yes"
                          ? data.franchise_subscription
                            ? " / " +
                              moment(
                                data.franchise_subscription.renew_date
                              ).format(commonValue.commaSeparatedShowDateTime)
                            : ""
                          : ""}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Industry Type
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.indus_category ? data.indus_category.name : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        User Email
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.user ? data.user.email : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="py-4 border-grey">
                  <Col sm={6} lg={6} xl={6}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Description
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.description ? data.description : "-"}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col sm={6} lg={6} xl={6}>
                    <div className="detailVideo">
                      <Carousel interval={null}>
                        {mediaData.map((media, index) => (
                          <Carousel.Item key={index}>
                            {media.media_type === "image" &&
                            media.media_file ? (
                              <img
                                src={media.media_file_name}
                                alt={`Image ${index}`}
                              />
                            ) : media.media_type === "video" &&
                              media.media_file ? (
                              <video controls autoPlay={false}>
                                <source
                                  src={media.media_file_name}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <></>
                            )}
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  </Col>
                </Row>

                <Row className="py-4 border-grey">
                  {detailsDataTwo.part1.map((data) => {
                    const { title, detail } = data;
                    return (
                      <Col key={title} sm={6} lg={4} xl={3}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            {title}
                          </Form.Label>
                          <div className="static-data fs-16">
                            {detail ? detail : "-"}
                          </div>
                        </Form.Group>
                      </Col>
                    );
                  })}
                </Row>

                <Row className="py-4 border-grey">
                  {detailsDataTwo.part2.map((data) => {
                    const { title, detail } = data;
                    return (
                      <Col key={title} sm={6} lg={4} xl={3}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            {title}
                          </Form.Label>
                          <div className="static-data fs-16">
                            {detail ? detail : "-"}
                          </div>
                        </Form.Group>
                      </Col>
                    );
                  })}
                </Row>

                <Row className="py-4 border-grey">
                  <Col sm={3} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Address
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.address ? data.address : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={3} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        City
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data?.city?.name ? data.city.name : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={3} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        State
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data?.state?.name ? data.state.name : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={3} lg={3} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Country
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data?.country?.name ? data.country.name : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="py-4 margin-minus">
                  <Col sm={6} lg={6} xl={6} className="ps-0">
                    <div className="detailTitle fs-18 text-black fw-medium mb-2 mt-3">
                      Award and Recognition Company
                    </div>
                    {awardData.length > 0 ? (
                      <>
                        {awardData.map((awardData) => {
                          return (
                            <>
                              {awardData.award_name ? (
                                <div className="border-grey py-3 ps-3">
                                  <Row>
                                    <>
                                      <Col
                                        key={awardData.id}
                                        sm={6}
                                        lg={6}
                                        xl={6}
                                      >
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Award Name
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {awardData.award_name}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                      <Col
                                        key={awardData.id + "test"}
                                        sm={6}
                                        lg={6}
                                        xl={6}
                                      >
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Recognition Company
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {awardData.award_company_name}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    </>
                                  </Row>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Row>
                        <Col sm={6} lg={6} xl={6} className="">
                          -
                        </Col>
                      </Row>
                    )}
                  </Col>

                  <Col sm={6} lg={6} xl={6}>
                    <div className="detailTitle fs-18 text-black fw-medium mb-2 mt-3">
                      Relevant Links
                    </div>
                    {linkData.length > 0 ? (
                      <>
                        {linkData.map((data) => {
                          return (
                            <>
                              {data.relevant_link ? (
                                <div className="border-grey py-3 ps-3">
                                  <Row>
                                    <Col key={data.id} sm={12} lg={12} xl={12}>
                                      <Form.Group className="pb-1 lh-sm">
                                        <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                          Relevant Link
                                        </Form.Label>
                                        <div className="static-data fs-16">
                                          <a
                                            href={data.relevant_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="relLinks"
                                          >
                                            {data.relevant_link}
                                          </a>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Row>
                        <Col sm={6} lg={6} xl={6} className="">
                          -
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Row className="py-4 pt-0">
                  <Col sm={6} lg={6} xl={6} className="ps-0">
                    <div className="detailTitle fs-18 text-black fw-medium mb-2 mt-3">
                      Upload Document Category
                    </div>
                    {documentData.length > 0 ? (
                      <>
                        {documentData.map((data) => {
                          return (
                            <>
                              {data.document_file_name ? (
                                <span key={data.id}>
                                  <div className="border-grey py-3 ps-3">
                                    <Row>
                                      <Col sm={6} lg={6} xl={6}>
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Document
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {data.document_file}
                                            <button
                                              className="downloadBtn border-0 bg-transparent p-0 ms-2"
                                              onClick={() =>
                                                handleDownload(
                                                  data.document_file_name
                                                )
                                              }
                                            >
                                              <svg
                                                width="11.2"
                                                height="13"
                                                viewBox="0 0 11.2 13"
                                              >
                                                <defs>
                                                  <clipPath id="clip-path">
                                                    <rect
                                                      width="11.2"
                                                      height="13"
                                                      fill="none"
                                                    />
                                                  </clipPath>
                                                </defs>
                                                <g
                                                  id="Repeat_Grid_30"
                                                  data-name="Repeat Grid 30"
                                                  clip-path="url(#clip-path)"
                                                >
                                                  <g transform="translate(-1063.55 -275)">
                                                    <path
                                                      id="Icon_material-file-download"
                                                      data-name="Icon material-file-download"
                                                      d="M16.95,8.55h-2.7V4.5H10.2V8.55H7.5l4.725,4.725ZM7.5,14.625v1.35h9.45v-1.35Z"
                                                      transform="translate(1056.05 270.5)"
                                                      fill="#6a3f91"
                                                    />
                                                  </g>
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} lg={6} xl={6}>
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Document Name
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {data.document_name}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Row>
                        <Col sm={6} lg={6} xl={6} className="">
                          -
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
