import {
  CHANGE_PASSWORD,
  CHANGE_PROFILE,
  USER_DASHBOARD,

  USER_INFO,
  USER_LIST,
  USER_STATUS_UPDATE,
  USER_REPORTS
} from "../constants/paths";
import { axios } from "./axios";

/**
 * get user info
 * @date 17/07/2023 - 12:48:51
 *
 * @returns {*}
 */
export const getUserDetails = () => {
  return axios.get(USER_INFO);
};

/**
 * change Profile
 * @date 17/07/2023 - 12:48:42
 *
 * @param {*} data
 * @returns {*}
 */
export const changeProfile = (data) => {
  const config = { headers: { "content-type": "multipart/form-data" } };
  return axios.put(CHANGE_PROFILE, data, config);
};

export const changePassword = (data) => {
  return axios.put(CHANGE_PASSWORD, data);
};

export const getUserList = (params) => {
  return axios.get(`${USER_LIST}`, { params });
};
export const updateUserBlockStatus = async (data) => {
  return axios.post(`${USER_STATUS_UPDATE}`, data);
};
export const getdashboard = () => {
  return axios.get(USER_DASHBOARD);
};
export const getReports = (data) => {
  return axios.get(`${USER_REPORTS}?isLocation=${data.isLocation}&month=${data.month}&year=${data.year}`);
};


