import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { EditCms } from "./Edit/Edit";
import { AllCms } from "./List/List";
import { View } from "./View/View";

export const Cms = () => {
  storage.setPageTitle("CMS Pages");
  return (
    <Routes>
      <Route path="/" element={<AllCms />} />

      <Route path="/edit/:id" element={<EditCms />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
