import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.svg";
import {
  getFranchiseDetails,
  getSellerDetails,
} from "../../../services/franchiseService";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import commonValue from "../../../constants/commonValue";
import moment from "moment";
import {
  CapitalText,
  formatNumberWithTwoDecimals,
} from "../../../helpers/helpersMethods";

export const View = () => {
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailsDataTwo, setDetailsDataTwo] = useState({
    part1: [],
    part2: [],
  });
  const [awardData, setAwardData] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getSellerDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.seller.path);
          }
        }
      );
    }
  }, [id]);
  useEffect(() => {
    setDocumentData(data.seller_document);
    setMediaData(data.seller_media);
    // setDetailsDataTwo({
    //   part1: [
    //     {
    //       title: "Total Units",
    //       detail: data.total_units,
    //     },
    //     {
    //       title: "Year Founded",
    //       detail: data.year_founded,
    //     },
    //     {
    //       title: "Founder Name",
    //       detail: data.founder_name,
    //     },
    //     {
    //       title: "Franchise Since",
    //       detail: data.franchise_since,
    //     },
    //   ],
    //   part2: [
    //     {
    //       title: "Verterned Own Unit",
    //       detail: data.verterned_own_unit,
    //     },
    //     {
    //       title: "Employees",
    //       detail: data.employees,
    //     },
    //     {
    //       title: "Annual Revenue",
    //       detail: data.annual_revenue,
    //     },
    //     {
    //       title: "Net Worth",
    //       detail: data.net_worth,
    //     },
    //   ],
    // });
  }, [data]);
  const handleDownload = (documentFileName) => {
    // Replace 'BASE_URL' with the actual base URL where your files are located.
    const fileUrl = documentFileName;

    // Open a new window with the file URL to trigger the download.
    window.open(fileUrl, "_blank");
  };
  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg width="29.4" height="26.46" viewBox="0 0 29.4 26.46">
                      <path
                        id="Icon_material-business-center"
                        data-name="Icon material-business-center"
                        d="M14.76,23.61V22.14H4.485L4.47,28.02a2.93,2.93,0,0,0,2.94,2.94H27.99a2.93,2.93,0,0,0,2.94-2.94V22.14H20.64v1.47Zm14.7-13.23H23.566V7.44L20.626,4.5h-5.88l-2.94,2.94v2.94H5.94A2.949,2.949,0,0,0,3,13.32v4.41a2.93,2.93,0,0,0,2.94,2.94h8.82V17.73h5.88v2.94h8.82a2.949,2.949,0,0,0,2.94-2.94V13.32A2.949,2.949,0,0,0,29.46,10.38Zm-8.82,0H14.76V7.44h5.88Z"
                        transform="translate(-3 -4.5)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Business Seller Details
                </h5>

                <Link
                  to={nameBasedProtectedRoutes.seller.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={3}>
                    <div className="d-flex">
                      <div className="detailSideCol">
                        <span>
                          <Image src={data.logo ? data.logo_link : Logo} />
                        </span>
                      </div>
                      <Form.Group className="pb-1 lh-sm">
                        <Form.Label className="mb-1 fw-normal labelColor fs-13">
                          Business Name
                        </Form.Label>
                        <div className="static-data fs-16">{data.name}</div>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Owner Name
                      </Form.Label>
                      <div className="static-data fs-16">{data.owner_name}</div>
                    </Form.Group>
                  </Col>
                  {/* <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Industry Type
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.indus_category ? data.indus_category.name : "-"}
                      </div>
                    </Form.Group>
                  </Col> */}
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        User Email
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.user ? data.user.email : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="pt-4 pb-1">
                  <Col sm={6} lg={6} xl={6} className="border-grey">
                    <Row className="pt-1 pb-3 border-grey">
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            Address
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data.address ? data.address : "-"}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            City / State / Country
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data?.city?.name ? data.city.name : "-"} / {data?.state?.name ? data.state.name : "-"} / {data?.country?.name ? data.country.name : "-"} 
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="py-4 border-grey">
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            Date Opened
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data.date_opened
                              ? moment(data.date_opened).format(
                                  commonValue.commaSeparatedShowDateTime
                                )
                              : ""}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            Franchise Agreement Upload
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data.agreement_upload}
                            {data.agreement_upload ? (
                              <button
                                className="downloadBtn border-0 bg-transparent p-0 ms-2"
                                onClick={() =>
                                  handleDownload(data.agreement_upload_link)
                                }
                              >
                                <svg
                                  width="11.2"
                                  height="13"
                                  viewBox="0 0 11.2 13"
                                >
                                  <defs>
                                    <clipPath id="clip-path">
                                      <rect
                                        width="11.2"
                                        height="13"
                                        fill="none"
                                      />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="Repeat_Grid_30"
                                    data-name="Repeat Grid 30"
                                    clip-path="url(#clip-path)"
                                  >
                                    <g transform="translate(-1063.55 -275)">
                                      <path
                                        id="Icon_material-file-download"
                                        data-name="Icon material-file-download"
                                        d="M16.95,8.55h-2.7V4.5H10.2V8.55H7.5l4.725,4.725ZM7.5,14.625v1.35h9.45v-1.35Z"
                                        transform="translate(1056.05 270.5)"
                                        fill="#6a3f91"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="py-4">
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            Annual Revenue
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data.annual_revenue}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={6} xl={6}>
                        <Form.Group className="pb-1 lh-sm">
                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                            Cost Of Doing business
                          </Form.Label>
                          <div className="static-data fs-16">
                            {data.cost_of_business
                              ? data.cost_of_business
                              : "-"}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6} lg={6} xl={6} className="border-grey">
                    <div className="detailVideo">
                      {mediaData.length > 0 ? (
                        <Carousel interval={null}>
                          {mediaData.map((media, index) => (
                            <Carousel.Item key={index}>
                              {media.media_type === "image" &&
                              media.media_file ? (
                                <img
                                  src={media.media_file_name}
                                  alt={`Image ${index}`}
                                />
                              ) : media.media_type === "video" &&
                                media.media_file ? (
                                <video controls autoPlay={false}>
                                  <source
                                    src={media.media_file_name}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : (
                                <></>
                              )}
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Lease Upload
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.lease_upload ? (
                          <>
                            {data.lease_upload}
                            {data.lease_upload ? (
                              <button
                                className="downloadBtn border-0 bg-transparent p-0 ms-2"
                                onClick={() =>
                                  handleDownload(data.lease_upload_link)
                                }
                              >
                                <svg
                                  width="11.2"
                                  height="13"
                                  viewBox="0 0 11.2 13"
                                >
                                  <defs>
                                    <clipPath id="clip-path">
                                      <rect
                                        width="11.2"
                                        height="13"
                                        fill="none"
                                      />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="Repeat_Grid_30"
                                    data-name="Repeat Grid 30"
                                    clip-path="url(#clip-path)"
                                  >
                                    <g transform="translate(-1063.55 -275)">
                                      <path
                                        id="Icon_material-file-download"
                                        data-name="Icon material-file-download"
                                        d="M16.95,8.55h-2.7V4.5H10.2V8.55H7.5l4.725,4.725ZM7.5,14.625v1.35h9.45v-1.35Z"
                                        transform="translate(1056.05 270.5)"
                                        fill="#6a3f91"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Year Left On Lease
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.year_left_lease ? data.year_left_lease : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        P&L Upload
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.p_n_l_upload}
                        {data.p_n_l_upload ? (
                          <button
                            className="downloadBtn border-0 bg-transparent p-0 ms-2"
                            onClick={() =>
                              handleDownload(data.p_n_l_upload_link)
                            }
                          >
                            <svg width="11.2" height="13" viewBox="0 0 11.2 13">
                              <defs>
                                <clipPath id="clip-path">
                                  <rect width="11.2" height="13" fill="none" />
                                </clipPath>
                              </defs>
                              <g
                                id="Repeat_Grid_30"
                                data-name="Repeat Grid 30"
                                clip-path="url(#clip-path)"
                              >
                                <g transform="translate(-1063.55 -275)">
                                  <path
                                    id="Icon_material-file-download"
                                    data-name="Icon material-file-download"
                                    d="M16.95,8.55h-2.7V4.5H10.2V8.55H7.5l4.725,4.725ZM7.5,14.625v1.35h9.45v-1.35Z"
                                    transform="translate(1056.05 270.5)"
                                    fill="#6a3f91"
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Year in Business
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.year_in_business ? data.year_in_business : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Asking Price
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.asking_price
                          ? formatNumberWithTwoDecimals(data.asking_price)
                          : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Negotiable Price
                      </Form.Label>
                      <div className="static-data fs-16 text_uppercase">
                        {data.is_price_negotiable
                          ? CapitalText(data.is_price_negotiable)
                          : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <Form.Group className="pb-1 lh-sm">
                      <Form.Label className="mb-1 fw-normal labelColor fs-13">
                        Biggest Expense
                      </Form.Label>
                      <div className="static-data fs-16">
                        {data.biggest_expense ? data.biggest_expense : "-"}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="py-4 pt-3">
                  <Col sm={6} lg={6} xl={6} className="ps-0">
                    <div className="detailTitle fs-18 text-black fw-medium mb-2 mt-3">
                      Contact Information
                    </div>

                    <div className="border-grey py-3 ps-3">
                      <Row>
                        <Col sm={6} lg={6} xl={6}>
                          <Form.Group className="pb-4 lh-sm">
                            <Form.Label className="mb-1 fw-normal labelColor fs-13">
                              Phone Number
                            </Form.Label>
                            <div className="static-data fs-16">
                              {data.contact_phone ? data.contact_phone : "-"}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={6} xl={6}>
                          <Form.Group className="pb-4 lh-sm">
                            <Form.Label className="mb-1 fw-normal labelColor fs-13">
                              Email Address
                            </Form.Label>
                            <div className="static-data fs-16">
                              {data.contact_email ? data.contact_email : "-"}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={6} lg={6} xl={6}>
                          <Form.Group className="pb-1 lh-sm">
                            <Form.Label className="mb-1 fw-normal labelColor fs-13">
                              Contact Prefrence
                            </Form.Label>
                            <div className="static-data fs-16 text_uppercase">
                              {data.contact_prefrence
                                ? CapitalText(data.contact_prefrence)
                                : "-"}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={6} lg={6} xl={6} className="ps-0">
                    <div className="detailTitle fs-18 text-black fw-medium mb-2 mt-3">
                      Upload Document Category
                    </div>
                    {documentData.length > 0 ? (
                      <>
                        {documentData.map((data) => {
                          return (
                            <>
                              {data.document_file ? (
                                <span key={data.id}>
                                  <div className="border-grey py-3 ps-3">
                                    <Row>
                                      <Col sm={6} lg={6} xl={6}>
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Document
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {data.document_file}
                                            {data.document_file ? (
                                              <button
                                                className="downloadBtn border-0 bg-transparent p-0 ms-2"
                                                onClick={() =>
                                                  handleDownload(
                                                    data.document_file_name
                                                  )
                                                }
                                              >
                                                <svg
                                                  width="11.2"
                                                  height="13"
                                                  viewBox="0 0 11.2 13"
                                                >
                                                  <defs>
                                                    <clipPath id="clip-path">
                                                      <rect
                                                        width="11.2"
                                                        height="13"
                                                        fill="none"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                  <g
                                                    id="Repeat_Grid_30"
                                                    data-name="Repeat Grid 30"
                                                    clip-path="url(#clip-path)"
                                                  >
                                                    <g transform="translate(-1063.55 -275)">
                                                      <path
                                                        id="Icon_material-file-download"
                                                        data-name="Icon material-file-download"
                                                        d="M16.95,8.55h-2.7V4.5H10.2V8.55H7.5l4.725,4.725ZM7.5,14.625v1.35h9.45v-1.35Z"
                                                        transform="translate(1056.05 270.5)"
                                                        fill="#6a3f91"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} lg={6} xl={6}>
                                        <Form.Group className="pb-1 lh-sm">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">
                                            Document Name
                                          </Form.Label>
                                          <div className="static-data fs-16">
                                            {data.document_name}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Row>
                        <Col sm={6} lg={6} xl={6} className="">
                          -
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
