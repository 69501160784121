import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { Formik, useFormikContext } from "formik";
import InputText from "../../../components/common/InputText";
import { toast } from "react-toastify";
import { AddEntitiyMethod } from "../../../helpers/helpersMethods";
import Loader from "../../../components/common/Loader";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { categorySchema, cmsSchema } from "../../../validations/Category";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { cmsUpdate, getCmsDetails } from "../../../services/cmsService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const EditCms = () => {
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const formik = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [status, setStatus] = useState("active");
  const [redirect, setRedirect] = useState();
  const [data, setData] = useState();
  const [pageTitle, setPageTitle] = useState();
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: ["black"] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link"],

      ["clean"],
    ],
  };
  const { id } = useParams();
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    setRedirect(nameBasedProtectedRoutes.cms.path);
    if (id) {
      getCmsDetails(id)
        .then(
          (resp) => {
            const res = resp?.data;
            setData(res);
            setPageTitle(res.title);
          },
          (error) => {
            const err = error?.response?.data;
            if (err?.statusCode === 400) {
              toast.error(err.msg);
              navigate(nameBasedProtectedRoutes.cms.path);
            }
          }
        )
        .finally(() => {
          setLoading(false); // Set loading to false when the effect finishes
        });
    } else {
      setLoading(false); // Set loading to false when there's no 'id'
    }
  }, [id, navigate]);

  const handleSubmit = (values, setErrors) => {
    if (id) {
      toast.dismiss();
      let content = values.description.replace(/<[^>]+>/g, "");
      if (content.length == 0) {
        setErrors({
          description: "Field is Required.",
        });
      } else {
        delete values.title;
        values.description = values.description.replace(
          /<p><br><\/p>/g,
          "<p></p>"
        );
        AddEntitiyMethod(
          setLoading,
          cmsUpdate,
          values,
          "",
          nameBasedProtectedRoutes.cms.path,
          navigate
        );
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.62"
                      height="24.62"
                      viewBox="0 0 24.62 24.62"
                    >
                      <g
                        id="Architecture_and_city"
                        data-name="Architecture and city"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_28725"
                          data-name="Path 28725"
                          d="M0,165.714a2.648,2.648,0,0,0,2.645,2.645H21.975a2.648,2.648,0,0,0,2.645-2.645V151H0Zm14.474-2.453H10.146a1.443,1.443,0,1,1,0-2.885h4.328a1.443,1.443,0,1,1,0,2.885Zm-6.492-9.377h8.655a.721.721,0,1,1,0,1.443H7.982a.721.721,0,1,1,0-1.443ZM5.1,156.77H19.523a.721.721,0,1,1,0,1.443H5.1a.721.721,0,1,1,0-1.443Zm0,0"
                          transform="translate(0 -143.739)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28726"
                          data-name="Path 28726"
                          d="M21.975,0H2.644A2.648,2.648,0,0,0,0,2.645V5.818H24.62V2.645A2.648,2.648,0,0,0,21.975,0Zm-6.78,4.376a.721.721,0,1,1,.721-.721A.721.721,0,0,1,15.195,4.376Zm2.885,0a.721.721,0,1,1,.721-.721A.721.721,0,0,1,18.08,4.376Zm2.885,0a.721.721,0,1,1,.721-.721A.721.721,0,0,1,20.965,4.376Zm0,0"
                          transform="translate(0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </div>
                  {pageTitle ? pageTitle : "Edit CMS Pages"}
                </h5>
              </Stack>
            </div>
            {data ? (
              <Formik
                validationSchema={cmsSchema}
                initialValues={{
                  id: data.id ? data.id : "",
                  title: data.title ? data.title : "",
                  description: data.description ? data.description : "",
                }}
                onSubmit={(values, actions) => {
                  values.title = values.title.trim();
                  values.description = values.description.trim();
                  actions.setSubmitting(false);
                  setFormSubmitted(true);
                  handleSubmit(values, actions.setErrors);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex-grow-1 d-flex flex-column px-1">
                      <div className="p-3 d-flex flex-column h-100">
                        <div className="d-flex flex-column flex-grow-1 theme-from">
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="title"
                                label="Title *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.title}
                                touched={touched.title}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                disabled={true}
                                value={values.title}
                                name="title"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={6} className="pb-1">
                              <Row>
                                <Col sm>
                                  <div className="position-relative right-col reactEditor">
                                    {/* <ReactQuill onBlur={handleBlur} onChange={handleChange} value={value} 
                                    className="theme-editor"
                                /> */}
                                    <label className="mb-1 fw-normal labelColor fs-13 form-label">
                                      Description
                                    </label>
                                    <ReactQuill
                                      theme="snow"
                                      value={values.description}
                                      onChange={(event) => {
                                        setFieldValue("description", event);
                                      }}
                                      modules={modules}
                                      placeholder="Description"
                                      className={`ctmeditor rounded overflow-hidden ${
                                        touched.description &&
                                        errors.description &&
                                        "is-invalid form-control"
                                      } `}
                                    />
                                    {touched.description &&
                                      errors.description && (
                                        <Form.Control.Feedback type="invalid">
                                          {String(errors.description)}
                                        </Form.Control.Feedback>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="my-3"
                          >
                            <Button
                              variant="primary"
                              className="rounded-lg fs-15 mw-84"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Link
                              to={nameBasedProtectedRoutes.cms.path}
                              className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                            >
                              Cancel
                            </Link>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              ""
            )}
          </Card>
        </div>
      </div>

      <Loader isLoading={loading} />
    </>
  );
};
