import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  MAXIMUM_32_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_8_CHAR,
  NAME_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  REQUIRED,
  // USER_NAME_REQUIRED,
  VALID_EMAIL,
} from "./ValidationErrors";

export const profileSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(NAME_REQUIRED),
  email: yup.string().trim().required(EMAIL_REQUIRED).email(VALID_EMAIL),
});

export const ChangePasswordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED),
    // .min(8, MINIMUM_8_CHAR)
    // .max(32, MAXIMUM_32_CHAR)
    // .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,PASSWORD_REGEX) 
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(32, MAXIMUM_32_CHAR)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
      PASSWORD_REGEX
    ),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("new_password"), ""], PASSWORD_MUST_MATCH),
});
