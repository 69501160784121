import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import Logo from "../../../assets/images/logo.svg";
import User from "../../../assets/images/user.png";
import storage from "../../../helpers/storage";
import { getUserDetails } from "../../../services/userService";

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header = ({ isActiveSidebar, toggleSidebarButton }) => {
  const UserProfile = "https://dummyimage.com/40x40.png";
  const userName = "John Due";
  const preFix = storage.getStoragePrefix();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Dashboard");
  const logOut = async () => {
    setLoading(true);
    localStorage.removeItem(`${preFix}token`);
    localStorage.removeItem("admin-user-info");
    navigate("/");
    setLoading(false);
  };
  useEffect(() => {
    const getUserData = storage.getData("admin-user-info");
    if (getUserData) {
      setTimeout(() => {
        const userId = getUserData.id;
        if (userId) {
          getUserDetails().then(
            (resp) => {
              const res = resp?.data;
              storage.setData("admin-user-info", res);
            },
            (error) => {}
          );
        }
      }, 1000);
    }
  }, []);
  useEffect(() => {
    setTitle(storage.getPageTitle ? storage.getPageTitle : "Dashboard");
  });
  const getUserData = storage.getData("admin-user-info");
  return (
    <Navbar bg="" variant="light" className="py-0 px-md-1">
      <Container fluid className="px-3 mh-60">
        <Button
          onClick={toggleSidebarButton}
          variant="link"
          className="align-items-center d-flex justify-content-center navMenuBtn p-0 bg-white rounded-circle h-40 w-40"
        >
          <span
            className={`bg-black d-inline-block menuTrigger position-relative text-center ${
              isActiveSidebar ? "active" : ""
            }`}
          ></span>
        </Button>
        <h1 className="fw-bold h4 mb-0 ms-2 ps-1 text-black">{title}</h1>
        <Link to="/" className="d-xl-none mx-2 mx-md-3 px-1">
          <Image
            className="img-fluid"
            src={Logo}
            alt="Logo"
            width={80}
            height={32}
          />
        </Link>
        <Nav className="ms-auto align-items-center">
          {/* <Dropdown className="notificationDropdown me-2">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-notification"
              className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2"
            >
              <FaBell size={18} color="#6A3F91" />
              <span className="align-items-center badge bg-danger border border-white customBadge d-flex fs-10 fw-semibold justify-content-center position-absolute px-1 rounded-pill start-100 top-0">
                5<span className="visually-hidden">unread messages</span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="fs-14 shadow-sm px-3">
              Coming soon...
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <div className="divider mx-1 mx-md-3 vr opacity-100"></div> */}

          <Dropdown className="profileDropdown ms-md-2 ms-1">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-profile"
              className="border-0 fs-14 fw-semibold text-decoration-none p-0 text-light"
            >
              <Image
                className="object-fit-cover rounded-circle"
                src={
                  getUserData
                    ? getUserData.profile_picture
                      ? getUserData.profile_picture
                      : User
                    : User
                }
                alt="Profile Image"
                width={30}
                height={30}
              />
              <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate mxw-90 fs-15 text-black fw-normal pe-1">
                {getUserData?.name}
              </span>
              {/*<FaAngleDown size={16} />*/}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              <Dropdown.Item as={Link} to="/app/profile" className="fw-medium">
                Profile
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/app/change-password"
                className="fw-medium"
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider className="mb-1" />
              <Dropdown.Item className="fw-medium" onClick={logOut}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
