import React, { useEffect, useState } from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import { DataGrid } from "@mui/x-data-grid";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";

import {
  AddEntitiyMethod,
  CapitalText,
  truncateText,
} from "../../../helpers/helpersMethods";

import { styled } from "@mui/material/styles";

import {
  categoryDelete,
  getCategoryList,
  categoryUpdate,
  categoryUpdateStatus,
} from "../../../services/categoryService";
import DeleteModal from "../../../components/common/DeleteModal";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { CgMenuGridR } from "react-icons/cg";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#047582",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllCategory = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [data, setData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);
  const cookies = new Cookies();
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectRow, setSelectRow] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      maxWidth: 250,
      sortable: false,
      renderCell: (props) => props.row.sNo,
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      renderCell: (props) => truncateText(props.row.name),
    },
    {
      field: "category_type",
      headerName: "Type",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      renderCell: (props) => truncateText(CapitalText(props.row.category_type)),
    },
    {
      field: "description",
      headerName: "Description",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      renderCell: (props) => truncateText(CapitalText(props.row.description)),
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 0.3,
      minWidth: 130,
      sortable: false,
      renderCell: (props) => {
        return (
          <SwitchCell
            defaultChecked={
              props.row.status == "inactive" ? "" : props.row.status
            }
            tooltip={props.row.status == "inactive" ? "Inactive" : "Active"}
            onClick={() =>
              statusToggle(props.row.id, props.row.status, props.row.source)
            }
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/category"
            isViewEnable={true}
            isEditEnable={true}
            isDeleteEnable={true}
            handleDeleteClick={() => deleteRow(props.row.id)}
            redirectID={props.row.id}
            rowData={props.row}
          />
        );
      },
    },
  ];

  // Searching and listing user list
  // const handleValueChange = (value, type) => {
  //   setSelectedValue(value);
  // };

  useEffect(() => {
    setCurrentPage(1);
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchtext: selectedValue,
    });
  }, [selectedValue]);

  // Get user list details
  const fetchList = (params) => {
    setLoading(true);
    getCategoryList({ ...params }).then(
      (response) => {
        const res = response?.data;
        const itemsPerPage = params?.limit || 10;
        const currentPage = params?.page || 1;
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        setData([]);
        let dataWithSNo = [];
        if (res.record.length > 0) {
          dataWithSNo = res.record.map((item, index) => ({
            ...item,
            sNo: startIndex + index,
          }));
          // Rest of your code that uses `dataWithSNo`
        }
        setLoading(false);
        setData(dataWithSNo);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        setLoading(false);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  };

  // On page load fetch user list
  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      fetchList({
        page: currentPage,
        limit: rowPerPage,
        searchtext: selectedValue,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      });
    }
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, [isMounted, page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };
  //Delete record
  const deleteRow = (id) => {
    if (id) {
      setDeleteRecordId(id);
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const recordDelete = () => {
    if (deleteRecordId) {
      setLoading(true);
      setShow(false);
      categoryDelete(deleteRecordId).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
            setShow(false);
            toast.success(resp.msg);
            setCurrentPage(1);
            fetchList({ page: currentPage, limit: rowPerPage });
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          setShow(false);
          if (err?.statusCode === 400) {
            //toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  };
  const statusToggle = (id, value, type) => {
    toast.dismiss();
    const status = value == "active" ? "inactive" : "active";
    const changeStatusData = { id: id, status: status };
    if (id) {
      AddEntitiyMethod(
        setLoading,
        categoryUpdateStatus,
        changeStatusData,
        "",
        nameBasedProtectedRoutes.category.path,
        navigate
      );
      setTimeout(() => {
        setLoading(false);
        fetchList({
          page: currentPage,
          limit: rowPerPage,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          searchtext: selectedValue,
        });
      }, 1000);
    }
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <CgMenuGridR size={40} />
                  </div>
                  Category List
                </h5>
                <Link
                  to={nameBasedProtectedRoutes.categoryAdd.path}
                  className="btn btn-primary"
                >
                  Add New
                </Link>
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 d-flex align-items-end"
                    >
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        className="custom-text-field"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          setSelectedValue(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "";
                      const sortDirection = model[0]?.sort || "";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        setDeletestatus={setDeletestatus}
        recordDelete={recordDelete}
      />
    </>
  );
};
