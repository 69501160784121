import {
  CATEGORY_ADD,
  CATEGORY_DELETE,
  CATEGORY_DETAILS,
  CATEGORY_LIST,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_STATUS,
  CMS_LIST,
} from "../constants/paths";
import { axios } from "./axios";

export const categoryAdd = (data) => {
  return axios.post(`${CATEGORY_ADD}`, data);
};
export const categoryUpdate = (data) => {
  return axios.post(`${CATEGORY_UPDATE}`, data);
};

export const getCategoryList = (params) => {
  return axios.get(`${CATEGORY_LIST}`, { params });
};

export const categoryDelete = async (id) => {
  return axios.get(`${CATEGORY_DELETE}?id=${id}`);
};
export const getCategoryDetails = async (id) => {
  return axios.get(`${CATEGORY_DETAILS}?id=${id}`);
};
export const categoryUpdateStatus = async (data) => {
  return axios.post(`${CATEGORY_UPDATE_STATUS}`, data);
};
