import {
  FRANCHISE_DELETE,
  FRANCHISE_DETAILS,
  FRANCHISE_LIST,
  FRANCHISE_STATUS_UPDATE,
  LEAD_DETAILS,
  LEAD_LIST,
  LEAD_UPDATE,
  PAYMENT_DETAILS,
  PAYMENT_LIST,
  SELLER_DELETE,
  SELLER_DETAILS,
  SELLER_LIST,
  SELLER_STATUS_UPDATE,
  ADMIN_SUBSCRIPTION,
} from "../constants/paths";
import { axios } from "./axios";

/**
 * get user info
 * @date 17/07/2023 - 12:48:51
 *
 * @returns {*}
 */

export const getFranchiseList = (params) => {
  return axios.get(`${FRANCHISE_LIST}`, { params });
};

export const getFranchiseDetails = async (id) => {
  return axios.get(`${FRANCHISE_DETAILS}?id=${id}`);
};

export const franchiseDelete = async (id) => {
  return axios.get(`${FRANCHISE_DELETE}?id=${id}`);
};
export const updateFranchiesBlockStatus = async (data) => {
  return axios.post(`${FRANCHISE_STATUS_UPDATE}`, data);
};

//seller

export const getSellerList = (params) => {
  return axios.get(`${SELLER_LIST}`, { params });
};
export const sellerDelete = async (id) => {
  return axios.get(`${SELLER_DELETE}?id=${id}`);
};
export const getSellerDetails = async (id) => {
  return axios.get(`${SELLER_DETAILS}?id=${id}`);
};
export const updateSellerBlockStatus = async (data) => {
  return axios.post(`${SELLER_STATUS_UPDATE}`, data);
};

//Lead

export const getLeadList = (params) => {
  return axios.get(`${LEAD_LIST}`, { params });
};
export const updateLeadStatus = (data) => {
  return axios.post(`${LEAD_UPDATE}`, data);
};
export const getLeadDetails = async (id) => {
  return axios.get(`${LEAD_DETAILS}?id=${id}`);
};
//payment
export const getPaymentList = (params) => {
  return axios.get(`${PAYMENT_LIST}`, { params });
};
export const getPaymentDetails = async (id) => {
  return axios.get(`${PAYMENT_DETAILS}?id=${id}`);
};
export const addAdminSubscription = async (data) => {
  return axios.post(`${ADMIN_SUBSCRIPTION}`, data);
};
