import { Appaxios } from "./axios";
import { axios } from "./axios";
import {
  CATEGORY,
  CITY_ALL,
  CMS_DETAILS,
  CMS_LIST,
  CMS_UPDATE,
  COUNTRYES,
  PAGES,
  STATE_ALL,
} from "../constants/paths";

export const privacyPolicy = async (page) => {
  return Appaxios.get(PAGES + "?slug=" + page);
};

export const getState = async (id) => {
  return Appaxios.get(STATE_ALL + "?country_id=" + id);
};

export const getCity = async (id) => {
  return Appaxios.get(CITY_ALL + "?state_id=" + id);
};

export const getCmsList = (params) => {
  return axios.get(`${CMS_LIST}`, { params });
};
export const cmsUpdate = (data) => {
  return axios.post(`${CMS_UPDATE}`, data);
};
export const getCmsDetails = async (id) => {
  return axios.get(`${CMS_DETAILS}?id=${id}`);
};

/**
 * get Country
 * @date 18/07/2023 - 22:10:49
 *
 * @async
 * @returns {unknown}
 */
export const getCountry = async () => {
  return Appaxios.get(COUNTRYES);
};
export const getCategory = async (type) => {
  return Appaxios.get(CATEGORY + "?category_type=" + type);
};
