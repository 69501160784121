import {
  MdDashboard,
  MdSupervisorAccount,
  MdBusinessCenter,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { IoIosStats } from "react-icons/io";

export const NavItems = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: <MdDashboard size={24} />,
    path: "/app/dashboard",
  },
  {
    id: 2,
    // menuName: "User Management",
    menuName: "Users",
    menuIcon: <MdSupervisorAccount size={23} />,
    path: "/app/users",
  },
  {
    id: 3,
    // menuName: "Assessments",
    menuName: "Franchise",
    menuIcon: (
      <svg
        id="Franchise_3_"
        data-name="Franchise (3)"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="Path_28704"
          data-name="Path 28704"
          d="M49.172,319.3h6.367v1.172h1.172V319.3h6.368v1.172H64.25v-1.758a.586.586,0,0,0-.586-.586H56.711V317H55.539v1.133H48.586a.586.586,0,0,0-.586.586v1.758h1.172Z"
          transform="translate(-46.125 -304.617)"
          fill="#fff"
        />
        <path
          id="Path_28705"
          data-name="Path 28705"
          d="M390.336,436h-3.75a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,390.336,436Z"
          transform="translate(-370.922 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28706"
          data-name="Path 28706"
          d="M4.336,436H.586a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,4.336,436Z"
          transform="translate(0 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28707"
          data-name="Path 28707"
          d="M197.327,436h-3.75a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,197.327,436Z"
          transform="translate(-185.452 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28708"
          data-name="Path 28708"
          d="M117.569,1.172h.039V11.211h2.7V7.617a.586.586,0,0,1,.586-.586h3.048a.586.586,0,0,1,.586.586v3.594h2.7V1.172h.039a.586.586,0,0,0,0-1.172h-9.688a.586.586,0,0,0,0,1.172Zm2.969,1.172h3.751a.586.586,0,0,1,0,1.172h-3.751a.586.586,0,0,1,0-1.172Zm0,2.344h3.751a.586.586,0,0,1,0,1.172h-3.751a.586.586,0,0,1,0-1.172Z"
          transform="translate(-112.413)"
          fill="#fff"
        />
        <path
          id="Path_28709"
          data-name="Path 28709"
          d="M231.983,210h1.876v3.008h-1.876Z"
          transform="translate(-222.921 -201.797)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/franchise",
  },
  {
    id: 4,
    // menuName: "Onboarding Questions",
    menuName: "Business Seller",
    menuIcon: <MdBusinessCenter size={21} />,
    path: "/app/seller",
  },
  {
    id: 5,
    menuName: "Categories",
    menuIcon: <CgMenuGridR size={21} />,
    path: "/app/category",
  },
  {
    id: 6,
    menuName: "Leads",
    menuIcon: (
      <svg
        id="Job_search"
        data-name="Job search"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="Job_Search-2" data-name="Job_Search">
          <g
            id="Group_21747"
            data-name="Group 21747"
            transform="translate(0 2.344)"
          >
            <path
              id="Path_28727"
              data-name="Path 28727"
              d="M11.961,68.789,8.789,71.961h3.172Z"
              transform="translate(-8.446 -68.446)"
              fill="#fff"
            />
            <path
              id="Path_28728"
              data-name="Path 28728"
              d="M12.3,69.375A6.434,6.434,0,0,1,6.571,60H4.688v4.688H0V77.656H12.891V69.345C12.7,69.363,12.5,69.375,12.3,69.375ZM2.344,67.031H7.031V68.2H2.344Zm8.2,8.2h-8.2V74.063h8.2Zm0-2.344h-8.2V71.719h8.2Zm0-2.344h-8.2V69.375h8.2Z"
              transform="translate(0 -60)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_21748"
            data-name="Group 21748"
            transform="translate(7.031)"
          >
            <circle
              id="Ellipse_18"
              data-name="Ellipse 18"
              cx="0.586"
              cy="0.586"
              r="0.586"
              transform="translate(4.688 3.516)"
              fill="#fff"
            />
            <path
              id="Path_28729"
              data-name="Path 28729"
              d="M273.516,181.758a1.758,1.758,0,0,0-3.516,0v1.435a4.947,4.947,0,0,0,3.516,0Z"
              transform="translate(-266.484 -172.969)"
              fill="#fff"
            />
            <path
              id="Path_28730"
              data-name="Path 28730"
              d="M185.273,0a5.272,5.272,0,0,0-2.93,9.656V8.789a2.933,2.933,0,0,1,2.93-2.93A1.758,1.758,0,1,1,187.031,4.1a1.76,1.76,0,0,1-1.758,1.758,2.933,2.933,0,0,1,2.93,2.93v.867A5.272,5.272,0,0,0,185.273,0Z"
              transform="translate(-180)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_28731"
          data-name="Path 28731"
          d="M421.263,240.377a6.447,6.447,0,0,1-.826.831l2.748,2.865.829-.829Z"
          transform="translate(-404.014 -230.987)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/leads",
  },
  {
    id: 7,
    menuName: "Transactions",
    menuIcon: (
      <svg
        id="Wallet_1_"
        data-name="Wallet (1)"
        xmlns="http://www.w3.org/2000/svg"
        width="18.372"
        height="18.372"
        viewBox="0 0 18.372 18.372"
      >
        <path
          id="Path_28719"
          data-name="Path 28719"
          d="M62.845,2.79A1.8,1.8,0,0,1,64.64,1h.621L65.073.475a.717.717,0,0,0-.921-.431L57.878,2.327a.718.718,0,0,0-.428.921l.332.9h5.064Z"
          transform="translate(-55.346)"
          fill="#fff"
        />
        <path
          id="Path_28720"
          data-name="Path 28720"
          d="M239.718,57.745a.718.718,0,0,0-.718.718v1.364h6.28V58.463a.718.718,0,0,0-.718-.718Z"
          transform="translate(-230.424 -55.673)"
          fill="#fff"
        />
        <path
          id="Path_28721"
          data-name="Path 28721"
          d="M16.757,155.021H14.246a2.691,2.691,0,0,1,0-5.382h2.512V147.18a1.435,1.435,0,0,0-1.435-1.435H1.435A1.435,1.435,0,0,0,0,147.18v10.272a1.435,1.435,0,0,0,1.435,1.435H15.322a1.435,1.435,0,0,0,1.435-1.435Z"
          transform="translate(0 -140.515)"
          fill="#fff"
        />
        <path
          id="Path_28722"
          data-name="Path 28722"
          d="M357.2,284.245h-3.588a1.615,1.615,0,0,0,0,3.229H357.2a.539.539,0,0,0,.538-.538v-2.153A.539.539,0,0,0,357.2,284.245Zm-3.588,2.153a.538.538,0,1,1,.538-.538A.538.538,0,0,1,353.615,286.4Z"
          transform="translate(-339.369 -274.045)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/transaction",
  },

  // {
  //   id: 8,
  //   menuName: "Assessment Reports",
  //   menuIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15.779" height="20.298" viewBox="0 0 15.779 20.298">
  //               <g id="Clipboard" transform="translate(-57)">
  //                 <path id="Path_28723" data-name="Path 28723" d="M114.784,5.035h7.77a1.786,1.786,0,0,0,1.784-1.784V1.784A1.786,1.786,0,0,0,122.554,0h-7.77A1.786,1.786,0,0,0,113,1.784V3.251A1.786,1.786,0,0,0,114.784,5.035Z" transform="translate(-53.78)" fill="#fff"/>
  //                 <path id="Path_28724" data-name="Path 28724" d="M71.748,67.3v.583a2.977,2.977,0,0,1-2.973,2.973H61a2.977,2.977,0,0,1-2.973-2.973V67.3A2.972,2.972,0,0,0,57,69.546V81.955a2.977,2.977,0,0,0,2.973,2.973h9.832a2.977,2.977,0,0,0,2.973-2.973V69.546A2.972,2.972,0,0,0,71.748,67.3ZM62.2,79.821a.595.595,0,1,1-.968.691l-.309-.433-.309.433a.595.595,0,1,1-.968-.691l.546-.764-.546-.764a.595.595,0,1,1,.968-.691l.309.433.309-.433a.595.595,0,0,1,.968.691l-.546.764Zm.607-5.93-1.586,1.9a.595.595,0,0,1-.988-.115L59.6,74.41a.595.595,0,1,1,1.063-.533l.228.455,1-1.2a.595.595,0,0,1,.913.762Zm6.838,5.81H66.158a.595.595,0,0,1,0-1.189h3.489a.595.595,0,0,1,0,1.189Zm0-4.647H66.158a.595.595,0,0,1,0-1.189h3.489a.595.595,0,1,1,0,1.189Z" transform="translate(0 -64.63)" fill="#fff"/>
  //               </g>
  //             </svg>,
  //   path: "/app/settings"
  // },

  {
    id: 9,

    menuName: "CMS Pages",
    menuIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.985"
        height="15.985"
        viewBox="0 0 15.985 15.985"
      >
        <g
          id="Architecture_and_city"
          data-name="Architecture and city"
          transform="translate(0)"
        >
          <path
            id="Path_28725"
            data-name="Path 28725"
            d="M0,160.554a1.719,1.719,0,0,0,1.717,1.717H14.268a1.719,1.719,0,0,0,1.717-1.717V151H0Zm9.4-1.592H6.587a.937.937,0,1,1,0-1.873H9.4a.937.937,0,1,1,0,1.873Zm-4.215-6.088H10.8a.468.468,0,1,1,0,.937H5.183a.468.468,0,1,1,0-.937Zm-1.873,1.873h9.366a.468.468,0,1,1,0,.937H3.309a.468.468,0,1,1,0-.937Zm0,0"
            transform="translate(0 -146.286)"
            fill="#fff"
          />
          <path
            id="Path_28726"
            data-name="Path 28726"
            d="M14.268,0H1.717A1.719,1.719,0,0,0,0,1.717V3.778H15.985V1.717A1.719,1.719,0,0,0,14.268,0Zm-4.4,2.841a.468.468,0,1,1,.468-.468A.468.468,0,0,1,9.865,2.841Zm1.873,0a.468.468,0,1,1,.468-.468A.468.468,0,0,1,11.739,2.841Zm1.873,0a.468.468,0,1,1,.468-.468A.468.468,0,0,1,13.612,2.841Zm0,0"
            fill="#fff"
          />
        </g>
      </svg>
    ),
    path: "/app/cms",
  },
  {
    id: 10,

    menuName: "Reports & Stats",
    menuIcon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="14.667" height="16" viewBox="0 0 14.667 16">
        <g id="Icon_ionic-ios-stats" data-name="Icon ionic-ios-stats" transform="translate(-5.625 -4.5)">
          <path id="Path_28737" data-name="Path 28737" d="M12.708,20.5h2a.334.334,0,0,0,.333-.333V4.833a.334.334,0,0,0-.333-.333h-2a.334.334,0,0,0-.333.333V20.167A.334.334,0,0,0,12.708,20.5Z" transform="translate(-2.75 0)" fill="#fff"/>
          <path id="Path_28738" data-name="Path 28738" d="M5.958,26.917h2a.334.334,0,0,0,.333-.333v-6a.334.334,0,0,0-.333-.333h-2a.334.334,0,0,0-.333.333v6A.334.334,0,0,0,5.958,26.917Z" transform="translate(0 -6.417)" fill="#fff"/>
          <path id="Path_28739" data-name="Path 28739" d="M19.462,25.083h1.992a.336.336,0,0,0,.337-.337V16.088a.336.336,0,0,0-.337-.338H19.462a.336.336,0,0,0-.338.338v8.658A.336.336,0,0,0,19.462,25.083Z" transform="translate(-5.5 -4.583)" fill="#fff"/>
          <path id="Path_28740" data-name="Path 28740" d="M25.875,9.338V22a.336.336,0,0,0,.338.337H28.2A.336.336,0,0,0,28.542,22V9.338A.336.336,0,0,0,28.2,9H26.213A.336.336,0,0,0,25.875,9.338Z" transform="translate(-8.25 -1.833)" fill="#fff"/>
        </g>
      </svg>
    ),
    path: "/app/report",
  },

  // {
  //   id: 10,

  //   menuName: "Reports & Stats",
  //   menuIcon: <IoIosStats size={21} />,
  //   path: "/app/cms"
  // },
];
