import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { MdClose, MdHandshake, MdSupervisorAccount } from "react-icons/md";
import { MatDataGrid } from "../../../components/common/dataGrid/DataGrid";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getUserListAPI,
  changeUserStatus,
  deleteUser,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import {
  franchiseDelete,
  getFranchiseList,
  updateFranchiesBlockStatus,
  addAdminSubscription
} from "../../../services/franchiseService";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/common/dataGrid/CustomPagination";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  AddEntitiyMethod,
  formatNumberWithTwoDecimals,
  truncateText,
} from "../../../helpers/helpersMethods";
import { styled } from "@mui/material/styles";
import storage from "../../../helpers/storage";
import moment from "moment";
import commonValue from "../../../constants/commonValue";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#047582",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllFranchise = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [data, setData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);
  const cookies = new Cookies();
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);

  cookies.set("page_heading", "User Management"); // page heading
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "logo",
      headerName: "Logo",
      editable: false,
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      renderCell: (props) => (
        <div className="detailSideCol">
          <span>
            <img
              src={props.row.logo} // Assuming 'logo' contains the URL of the logo image
              alt={`Logo ${props.row.id}`}
              style={{ width: "100" }}
            />
          </span>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      renderCell: (props) => truncateText(props.row.name),
    },
    {
      field: "email",
      headerName: "User Email",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      renderCell: (props) => truncateText(props.row.user.email),
    },
    {
      field: "description",
      headerName: "Description",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => truncateText(props.row.description),
    },
    {
      field: "employees",
      headerName: "Employees",
      editable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (props) => truncateText(props.row.employees),
    },
    {
      field: "subscription_renew_date",
      headerName: "Subscription Date",
      editable: false,
      flex: 0.3,
      minWidth: 200,
      sortable: false,
      renderCell: (props) => {
        let disabled = false;
        if((props.row.is_admin_block == 'yes')){
          disabled = true;
        }
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} className="position-relative">
              {(
                <div
                  className="" 
                  onClick={() => {handleChangeDate(props.row.id, 'NULL',props.row.user.id)}}
                >
                  <MdClose size={21} color="#7c7a7a" />
                </div>
              )}
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  onChange={(newValue) => {
                    let newDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : "";
                    handleChangeDate(props.row.id, newDate,props.row.user.id);
                  }}
                  value={dayjs(props.row.subscription_renew_date)}
                  disabled={disabled}
                  className="custom-datepicker custom-text-field"
                  minDate={dayjs().subtract(0, "day")}
                  // value={dateChanged}
                  format="DD/MM/YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
        );
      },
    },


    {
      field: "net_worth",
      headerName: "Net Worth ($)",
      editable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (props) => formatNumberWithTwoDecimals(props.row.net_worth),
    },
    {
      field: "is_admin_block",
      headerName: "Status",
      editable: false,
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      renderCell: (props) => {
        return (
          <SwitchCell
            defaultChecked={
              props.row.is_admin_block == "yes" ? "" : props.row.is_admin_block
            }
            tooltip={props.row.is_admin_block == "yes" ? "Active" : "Inactive"}
            onClick={() => statusToggle(props.row.id, props.row.is_admin_block)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/franchise"
            isViewEnable={true}
            isEditEnable={false}
            isDeleteEnable={false}
            handleDeleteClick={() => deleteRow(props.row.id)}
            redirectID={props.row.id}
            rowData={props.row}
          />
        );
      },
    },
  ];

  const handleChangeDate = (id, date, userId) =>{
    toast.dismiss();
    if (date == 'NULL' && !(window.confirm("Do you really want to clear the subscription for this Fracnhise?"))) {
      return false;
    }
    setLoading(true);
    let postData = {subscription_id:1,franchise_business_type:"franchise",franchise_business_type_id:id,renew_date:date,user_id:userId};
    addAdminSubscription(postData).then(
      (response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          setShow(false);
          toast.success(response.msg);
          setCurrentPage(1);
          fetchList({ page: currentPage, limit: rowPerPage });
        }, 1000);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
          if(err?.msgCode == 7005){
            setTimeout(() => {
              setLoading(false);
              setShow(false);
              setCurrentPage(1);
              fetchList({ page: currentPage, limit: rowPerPage });
            }, 1000);
          }
          
        }
      }
    );
  }

  // Change user status

  // Change user status
  const deleteRow = (id) => {
    if (id) {
      setDeleteRecordId(id);
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const recordDelete = () => {
    if (deleteRecordId) {
      setLoading(true);
      setShow(false);
      franchiseDelete(deleteRecordId).then(
        (resp) => {
          setTimeout(() => {
            setLoading(false);
            setShow(false);
            //toast.success(resp.msg);
            setCurrentPage(1);
            fetchList({ page: currentPage, limit: rowPerPage });
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          setShow(false);
          if (err?.statusCode === 400) {
            //toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  };

  // Searching and listing user list
  const handleValueChange = (value) => {
    setSelectedValue(value);
    setCurrentPage(1);
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      ...(value ? { searchtext: value } : { searchtext: "" }),
    });
  };

  // Get user list details
  const fetchList = (params) => {
    //toast.dismiss();
    setLoading(true);
    getFranchiseList({ ...params }).then(
      (response) => {
        const res = response?.data;
        const itemsPerPage = params?.limit || 10;
        const currentPage = params?.page || 1;
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        setData([]);
        const dataWithSNo = res.record.map((item, index) => ({
          ...item,
          sNo: startIndex + index,
        }));
        setLoading(false);
        setData(dataWithSNo);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        setLoading(false);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  };

  // On page load fetch user list
  useEffect(() => {
    console.log("1", sortDirection);
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      searchtext: selectedValue,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  }, [page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };

  //setPaymentData
  const setPaymentData = (data) => {
    if (data) {
      storage.clearPaymentData("payment-data");
      storage.setData("payment-data", data);
      window.location.href = "stripe";
    }
  };
  const statusToggle = (id, value) => {
    const status = value == "yes" ? "no" : "yes";
    const changeStatusData = { id: id, is_admin_block: status };
    toast.dismiss();
    if (id) {
      AddEntitiyMethod(
        setLoading,
        updateFranchiesBlockStatus,
        changeStatusData,
        "",
        nameBasedProtectedRoutes.franchise.path,
        navigate
      );
      setTimeout(() => {
        setLoading(false);
        fetchList({
          page: currentPage,
          limit: rowPerPage,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          searchtext: selectedValue,
        });
      }, 1000);
    }
  };
  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Franchise_3_"
                      data-name="Franchise (3)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Path_28704"
                        data-name="Path 28704"
                        d="M49.758,320.457h9.55v1.758h1.758v-1.758h9.551v1.758h1.758v-2.637a.879.879,0,0,0-.879-.879H61.066V317H59.308v1.7H48.879a.879.879,0,0,0-.879.879v2.637h1.758Z"
                        transform="translate(-45.188 -298.426)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28705"
                        data-name="Path 28705"
                        d="M392.5,436h-5.625a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H392.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,392.5,436Z"
                        transform="translate(-363.383 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28706"
                        data-name="Path 28706"
                        d="M6.5,436H.879a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H6.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,6.5,436Z"
                        transform="translate(0 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28707"
                        data-name="Path 28707"
                        d="M199.495,436H193.87a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879h5.625a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,199.495,436Z"
                        transform="translate(-181.683 -410.453)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28708"
                        data-name="Path 28708"
                        d="M117.862,1.758h.059V16.816h4.043V11.426a.879.879,0,0,1,.879-.879h4.571a.879.879,0,0,1,.879.879v5.391h4.043V1.758h.059a.879.879,0,0,0,0-1.758H117.862a.879.879,0,0,0,0,1.758Zm4.453,1.758h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Zm0,3.516h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Z"
                        transform="translate(-110.129)"
                        fill="#fff"
                      />
                      <path
                        id="Path_28709"
                        data-name="Path 28709"
                        d="M231.983,210H234.8v4.512h-2.814Z"
                        transform="translate(-218.39 -197.695)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Franchise List
                </h5>

                {/* <Button variant="primary">Add Franchise</Button> */}
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        className="custom-text-field"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100 dataGridMedia dataGrid-datepicker">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "";
                      const sortDirection = model[0]?.sort || "";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                      // fetchList({
                      //   page,
                      //   limit: rowPerPage,
                      //   searchtext: selectedValue,
                      //   sortColumn: sortColumn,
                      //   sortDirection: sortDirection,
                      // });
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        setDeletestatus={setDeletestatus}
        recordDelete={recordDelete}
      />
    </>
  );
};
