import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getReports } from "../../../services/userService";
import Loader from "../../../components/common/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { yearData, monthData } from "../../../helpers/helpersMethods";
import {
  CapitalText,
  truncateText,
} from "../../../helpers/helpersMethods";
export const AllReports = () => {
  const [data, setData] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [locationDataGrid, setLocationDataGrid] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectYear, setSelectYear] = useState([]);
  const [selectMonth, setSelectMonth] = useState([]);
  const [locationSelectYear, setLocationSelectYear] = useState([]);
  const [locationSelectMonth, setLocationSelectMonth] = useState([]);
  const [isLocation, setIsLocation] = useState('');
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getReportData({
      month: selectMonth,
      year: selectYear,
      isLocation: isLocation
    });
  }, []);
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getReportData({
      month: selectMonth,
      year: selectYear,
      isLocation: isLocation
    });
  }, [selectYear, selectMonth]);

  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getReportData({
      month: locationSelectMonth,
      year: locationSelectYear,
      isLocation: isLocation
    });
  }, [locationSelectYear,locationSelectMonth]);
  const getReportData = (params) => {
    console.log('params',params);
    getReports({ ...params })
      .then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          if(Object.keys(res.stats).length !=0 && (isLocation == 0 || isLocation == 2)){
            const startIndex = 1;
            const dataWithSNo = res.stats.map((item, index) => ({
              ...item,
              sNo: startIndex + index,
            }));
            setDataGrid(dataWithSNo);
          }else if(isLocation == 0){
            setDataGrid({});
          }
          if(Object.keys(res.location).length !=0 && (isLocation == 0 || isLocation == 1)){
            const locationstartIndex = 1;
            const locationdataWithSNo = res.location.map((item, index) => ({
              ...item,
              sNo: locationstartIndex + index,
              id: locationstartIndex + index,
            }));
            setLocationDataGrid(locationdataWithSNo);
          }else if(isLocation == 1){
            setLocationDataGrid({});
          }
          setYearOptions(yearData());
          setMonthOptions(monthData());
        },
        (error) => {
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
          }
        }
      )
      .finally(() => {
        setLoading(false); // Set loading to false when the effect finishes
      });
  };
  const handleChangeYear = (event,gridTppe) => {
    console.log(event.target.value, "ccc");
    if(gridTppe == 'lead'){
      if (event.target.value) {
        setSelectYear(event.target.value);
      } else {
        setSelectYear("");
        setSelectMonth("");
      }
      setIsLocation(0);
    }else if(gridTppe == 'location'){
      if (event.target.value) {
        setLocationSelectYear(event.target.value);
      } else {
        setLocationSelectYear("");
        setLocationSelectMonth("");
      }
      setIsLocation(1);
    }else{
      setIsLocation("");
      setSelectYear("");
      setSelectMonth("");
    }
  };
  const handleChangeMonth = (event,gridTppe) => {
    if(gridTppe == 'lead'){
      if (event.target.value) {
        setSelectMonth(event.target.value);
      } else {
        setSelectYear("");
        setSelectMonth("");
      }
      setIsLocation(0);
    }else if(gridTppe == 'location'){
      if (event.target.value) {
        setLocationSelectMonth(event.target.value);
      } else {
        setLocationSelectYear("");
        setLocationSelectMonth("");
      }
      setIsLocation(1);
    }else{
      setIsLocation("");
      setSelectYear("");
      setSelectMonth("");
    }
  };
  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Business / Franchise Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => truncateText(CapitalText(props.name)),
    },
    {
      field: "source",
      headerName: "Type",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => truncateText(CapitalText(props.source)),
    },

    {
      field: "statename",
      headerName: "State Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => truncateText(CapitalText(props.statename)),
    },
    {
      field: "leadsCount",
      headerName: "Count",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
  ];
  const LocationGridColumns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "stateName",
      headerName: "State Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => truncateText(CapitalText(props.stateName)),
      
    },
    {
      field: "value",
      headerName: "Count",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
  ];
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      {/*<div className="pageHeader px-3 py-2 my-1">
        <Row className="align-items-center">
          <Col>
            <h1 className="fw-semibold h4 my-2">Dashboard</h1>
          </Col>
        </Row>
      </div>*/}
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="flex-grow-1 overflow-auto pageContent pb-3">
            <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
              <div className="dashboardTable bg-white w-100 mt-4">
                <div className="dashboardTableInner p-1">
                  <div className="dashboardTableTop d-flex justify-content-between mb-2 pb-1 px-2">
                    <div className="topLeft">
                      <h3>Most Asked Location</h3>
                    </div>
                    <div className="topRight d-flex">
                      <CustomSelect
                        label="Year"
                        placeholder="Select Year"
                        options={yearOptions}
                        name="Year"
                        value={locationSelectYear}
                        handleChange={(event) => handleChangeYear(event,'location')}
                      />
                      <CustomSelect
                        label="Month"
                        placeholder="Select Month"
                        options={monthOptions}
                        name="Month"
                        value={locationSelectMonth}
                        handleChange={(event) => handleChangeMonth(event,'location')}
                      />
                    </div>
                  </div>
                  {
                    <div className="dataGridMain dataGridMainHeight flex-fill px-1 w-100 dataGridMedia">
                      <DataGrid
                        id="locationDashboard"
                        slots={{
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={locationDataGrid}
                        columns={LocationGridColumns}
                        disableColumnMenu
                      />
                    </div>
                  }
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="flex-grow-1 overflow-auto pageContent pb-3">
            <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
              <div className="dashboardTable bg-white w-100 mt-4">
                <div className="dashboardTableInner p-1">
                  <div className="dashboardTableTop d-flex justify-content-between mb-2 pb-1 px-2">
                    <div className="topLeft">
                      <h3>Most Popular Franchise/Business</h3>
                    </div>
                    <div className="topRight d-flex">
                      <CustomSelect
                        label="Year"
                        placeholder="Select Year"
                        options={yearOptions}
                        name="Year"
                        value={selectYear}
                        handleChange={(event) => handleChangeYear(event,'lead')}
                      />
                      <CustomSelect
                        label="Month"
                        placeholder="Select Month"
                        options={monthOptions}
                        name="Month"
                        value={selectMonth}
                        handleChange={(event) => handleChangeMonth(event,'lead')}
                      />
                    </div>
                  </div>
                  {
                    <div className="dataGridMain dataGridMainHeight flex-fill px-1 w-100 dataGridMedia">
                      <DataGrid
                        id="dashboard"
                        slots={{
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={dataGrid}
                        columns={columns}
                        disableColumnMenu
                      />
                    </div>
                  }
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};
