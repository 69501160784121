export const LOGIN_API = `/users/login`;
export const FORGOT_API = `/users/forgot-password`;
export const RESET_API = `/users/reset-password`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/users/verify-user`;
export const LOGOUT = `/auth/logout`;
export const VERIFICATION_RESET_TOKEN = `/users/validateforgotpasswordtoken`;
export const REGISTER = `/users/register`;
export const PAGES = `/pages/detail`;
export const USER_INFO = `/users/profile`;
export const CHANGE_PROFILE = `/users/change-profile`;
export const CHANGE_PASSWORD = `/users/change-password`;
export const STATE_ALL = `/pages/get-states`;
export const CITY_ALL = `/pages/get-cities`;
export const COUNTRYES = `/pages/get-countries`;
export const FRANCHISE_LIST = `/franchise/list`;
export const FRANCHISE_DETAILS = `/franchise/details`;
export const FRANCHISE_DELETE = `/franchise/delete`;
export const FRANCHISE_STATUS_UPDATE = `/franchise/update-block-status`;
export const SELLER_LIST = `/seller/list`;
export const SELLER_DELETE = `/seller/delete`;
export const SELLER_DETAILS = `/seller/details`;
export const SELLER_STATUS_UPDATE = `/seller/update-block-status`;
export const CATEGORY = `/pages/get-category`;
export const USER_LIST = `/users/list`;
export const LEAD_LIST = `/lead/list`;
export const LEAD_UPDATE = `/lead/update`;
export const LEAD_DETAILS = `/lead/details`;
export const USER_STATUS_UPDATE = `/users/update-block-status`;
export const PAYMENT_LIST = `/payment/list`;
export const PAYMENT_DETAILS = `/payment/details`;
export const CATEGORY_ADD = `/category/add`;
export const CATEGORY_LIST = `/category/list`;
export const CATEGORY_DELETE = `/category/delete`;
export const CATEGORY_DETAILS = `/category/details`;
export const CATEGORY_UPDATE = `/category/update`;
export const CATEGORY_UPDATE_STATUS = `/category/update-status`;
export const CMS_LIST = `/page/list`;
export const CMS_UPDATE = `/page/update`;
export const CMS_DETAILS = `/page/details`;
export const USER_DASHBOARD = `/users/dashboard`;
export const USER_REPORTS = `/users/report-stats`;
export const ADMIN_SUBSCRIPTION = `/payment/add-subscription`;
