import React, { useEffect } from "react";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { AuthHeader } from "../authHeader/AuthHeader";

import { LoginSchema, registerSchema } from "../../../validations/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "universal-cookie";

import { useRef, useState } from "react";
import Loader from "../../../components/common/Loader";
import storage from "../../../helpers/storage";
import { toast } from "react-toastify";
import { CAPTCH_INVALID } from "../../../validations/ValidationErrors";
import { login } from "../../../services/authService";
import { roles } from "../../../constants/commonValue";
import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment"
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "@mui/icons-material/Mail";
import InputText from "../../../components/common/InputText";
import { nameBasedRoutes } from "../../../router/public";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { MdMail, MdKey, MdPerson } from "react-icons/md";
import { Box } from "@mui/material";
import { AddEntitiyMethod } from "../../../helpers/helpersMethods";
// import { userRegister } from "../../../services/authService"
//import Visibility from '@mui/icons-material/Visibility';
//import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [showConfirmPasEye, setShowConfirmPasEye] = useState(false);

  // to restricet admin only
  useEffect(() => {
    if (storage.getToken() != null) {
      const userInfo = storage.getData("admin-user-info");
      const role = userInfo ? userInfo?.role : "";
      if (role == "SUPER_ADMIN") {
        return navigate(nameBasedProtectedRoutes[0].dashborad.path);
      }
    }
  }, [navigate]);

  /**
   * Submit Form
   *

   */
  const submitForm = (value) => {
    toast.dismiss();
    // if (value) {
    //   AddEntitiyMethod(setLoading, userRegister, value, "xxx", nameBasedRoutes.login.path, navigate);
    // }
  }

  const showToggle = (event) => {
    setShowEye(event);
  };
  const onConfirmPasswordShowToggle = event => {
    setShowConfirmPasEye(event)
  }
  return (
    <>
      <Formik
        validationSchema={registerSchema}
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values, actions) =>
        {
          values.name = values.name.trim();
          values.email = values.email.trim();
          values.password = values.password.trim();
          const { confirmPassword, ...formData } = values;
          submitForm(formData)
        }}
      
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <div className="d-flex p-3 min-vh-100 w-100 theme-body-bg align-items-center">
            <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
              <div className="fw-normal text-center mb-5 pb-4">
                <AuthHeader />
              </div>
              <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100 shadow-sm pt-40 position-relative">
                <div className="credTopInfo position-absolute">
                  <p>
                    <span className="fw-medium">Register</span> with us
                  </p>
                  <p>Business Seller/ Franchise</p>
                </div>

                {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}

                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <MdPerson
                    className={`inputIco ${values.name.trim() !== "" ? "filled" : ""}`}
                    size="16"
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <InputText
                    controlId="name"
                    label="Name *"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.name}
                    touched={touched.name}
                    className="w-100 fs-16 mb-3 custom-text-field"
                    InputLabelProps={{
                      className: "fs-15 grey-color",
                    }}
                    value={values.name}
                    name="name"
                  />
                </Box>

                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <MdMail
                    className={`inputIco ${values.email.trim() !== "" ? "filled" : ""}`}
                    size="15"
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                  <InputText
                    controlId="email"
                    label="Email Address *"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.email}
                    touched={touched.email}
                    className="w-100 fs-16 mb-3 custom-text-field"
                    InputLabelProps={{
                      className: "fs-15 grey-color",
                    }}
                    value={values.email}
                    name="email"
                  />
                </Box>

                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <div
                    className={`position-relative password-field ${
                      showEye ? "form-right-icon" : ""
                    }`}
                  >
                    <MdKey
                      className={`inputIco ${values.password.trim() !== "" ? "filled" : ""}`}
                      size="15"
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <InputText
                      controlId="password"
                      label="Password *"
                      type={showEye ? "text" : "password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorsField={errors.password}
                      touched={touched.password}
                      value={values.password}
                      name="password"
                      className="w-100 mb-3 fs-16 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color",
                      }}
                    />

                    <div
                      className="credEye"
                      onClick={() => setShowEye(!showEye)}
                    >
                      {showEye ? (
                        <FaEye
                          size="22"
                          className="eyePosition light-grey"
                          onClick={() => showToggle(true)}
                        />
                      ) : (
                        <FaEyeSlash
                          size="22"
                          className="eyePosition light-grey"
                          onClick={() => showToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                </Box>

                <Box className="credCoverB" sx={{ display: "flex" }}>
                  <div
                    className={`position-relative password-field ${
                      showEye ? "form-right-icon" : ""
                    }`}
                  >
                    <MdKey
                      className={`inputIco ${values.confirmPassword.trim() !== "" ? "filled" : ""}`}
                      size="15"
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <InputText
                      controlId="confirmPassword"
                      label="Confirm Password *"
                      type={showConfirmPasEye ? "text" : "password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorsField={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      className="w-100 fs-16 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color",
                      }}
                    />

<div className="credEye" onClick={() => setShowConfirmPasEye(!showConfirmPasEye)}>
                      {showConfirmPasEye ? (
                            <FaEye size="22" 
                            className="eyePosition light-grey"
                            onClick={() => onConfirmPasswordShowToggle(true)} />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition light-grey"
                              onClick={() => onConfirmPasswordShowToggle(true)}
                            />
                          )}
                    </div>
                  </div>
                </Box>

                <Form.Group className="mt-4 pt-2 mb-3">
                  <Row
                    xs="auto"
                    className="align-items-center justify-content-between gx-0"
                  >
                    <Col className="d-flex align-items-center">
                      <Form.Check
                        id="isChecked"
                        className="fs-14 lh-lg mh-auto mb-0 user-select-none customCheck"
                        type="checkbox"
                        label=""
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                      <span className="fs-14 text-black pt-01">
                        I agree with the{" "}
                       
                        <Link to={nameBasedRoutes.termAndCondition.path} target="_blank" className="text-decoration-none link-color-blue">
                        terms of conditions</Link>
                        {" "}
                        and{" "}
                      
                        <Link to={nameBasedRoutes.privacyPolicy.path} target="_blank" className="text-decoration-none link-color-blue">
                        privacy policy</Link>
                      </span>
                    </Col>
                  </Row>
                </Form.Group>

                <Button
                  className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100 credBtns"
                  variant="primary"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={!isChecked} 
                >
                  Register 
                </Button>

                <p className="text-black fs-14 mt-3 mb-0 w-100 text-center">
                  Already have an account?{" "}
                 
                  <Link to={nameBasedRoutes.login.path} className="color-purple fw-bold text-decoration-none ms-1">
                  Login</Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <Loader isLoading={loading} />
    </>
  );
};
