import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllFranchise } from "./List/List";
import { View } from "./View/View";

export const Franchise = () => {
  storage.setPageTitle("Franchise");
  return (
    <Routes>
      <Route path="/" element={<AllFranchise />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
