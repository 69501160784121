import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllTransactions } from "./List/List";
import { View } from "./View/View";

export const Transactions = () => {
  storage.setPageTitle("Transactions");
  return (
    <Routes>
      <Route path="/" element={<AllTransactions />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
