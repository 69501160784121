import { Card, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { HiUsers } from "react-icons/hi2";
import { FaBlogger, FaConciergeBell } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";
import { ImVideoCamera } from "react-icons/im";
import { MdVideoLibrary, MdSupervisorAccount } from "react-icons/md";
import storage from "../../helpers/storage";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getdashboard } from "../../services/userService";
import Loader from "../../components/common/Loader";

export const Dashboard = () => {
  storage.setPageTitle("Dashboard");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getdashboard()
      .then(
        (resp) => {
          const res = resp?.data;
          setData(res);
        },
        (error) => {
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
          }
        }
      )
      .finally(() => {
        setLoading(false); // Set loading to false when the effect finishes
      });
  }, []);
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      {/*<div className="pageHeader px-3 py-2 my-1">
        <Row className="align-items-center">
          <Col>
            <h1 className="fw-semibold h4 my-2">Dashboard</h1>
          </Col>
        </Row>
      </div>*/}
      <div className="flex-grow-1 overflow-auto pageContent pb-3">
        <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
          <div className="text-center">
            <div className="row g-3 justify-content-start">
              {data ? (
                <>
                  <div className="col-xl col-md-3 col-sm-6 h-100">
                    <Card className="bggreenNew text-white">
                      <Card.Body>
                        <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
                          <MdSupervisorAccount size={45} />
                        </div>
                        <Card.Title className="fs-30 mt-3 mb-2 fw-normal">
                          {data ? data.total_users : "0"}
                        </Card.Title>
                        <Card.Text className="fs-16 fw-medium">
                          No. of Users
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 h-100">
                    <Card className="bggreen text-white">
                      <Card.Body>
                        <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
                          <svg
                            id="Franchise_3_"
                            data-name="Franchise (3)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="34.545"
                            height="34.545"
                            viewBox="0 0 34.545 34.545"
                          >
                            <path
                              id="Path_28704"
                              data-name="Path 28704"
                              d="M50.024,320.981h11V323h2.024v-2.024h11V323h2.024v-3.036a1.012,1.012,0,0,0-1.012-1.012H63.045V317H61.021v1.957H49.012A1.012,1.012,0,0,0,48,319.969V323h2.024Z"
                              transform="translate(-44.761 -295.612)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28705"
                              data-name="Path 28705"
                              d="M393.489,436h-6.477A1.012,1.012,0,0,0,386,437.012v3.1a1.012,1.012,0,0,0,1.012,1.012h6.477a1.012,1.012,0,0,0,1.012-1.012v-3.1A1.012,1.012,0,0,0,393.489,436Z"
                              transform="translate(-359.956 -406.583)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28706"
                              data-name="Path 28706"
                              d="M7.489,436H1.012A1.012,1.012,0,0,0,0,437.012v3.1a1.012,1.012,0,0,0,1.012,1.012H7.489A1.012,1.012,0,0,0,8.5,440.116v-3.1A1.012,1.012,0,0,0,7.489,436Z"
                              transform="translate(0 -406.583)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28707"
                              data-name="Path 28707"
                              d="M200.48,436H194a1.012,1.012,0,0,0-1.012,1.012v3.1A1.012,1.012,0,0,0,194,441.128h6.477a1.012,1.012,0,0,0,1.012-1.012v-3.1A1.012,1.012,0,0,0,200.48,436Z"
                              transform="translate(-179.97 -406.583)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28708"
                              data-name="Path 28708"
                              d="M118,2.024h.067v17.34h4.655V13.157a1.012,1.012,0,0,1,1.012-1.012h5.264a1.012,1.012,0,0,1,1.012,1.012v6.207h4.655V2.024h.067a1.012,1.012,0,0,0,0-2.024H118a1.012,1.012,0,1,0,0,2.024Zm5.128,2.024H129.6a1.012,1.012,0,1,1,0,2.024h-6.478a1.012,1.012,0,0,1,0-2.024Zm0,4.048H129.6a1.012,1.012,0,1,1,0,2.024h-6.478a1.012,1.012,0,0,1,0-2.024Z"
                              transform="translate(-109.09)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28709"
                              data-name="Path 28709"
                              d="M231.983,210h3.24v5.2h-3.24Z"
                              transform="translate(-216.331 -195.831)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <Card.Title className="fs-30 mt-3 mb-2 fw-normal">
                          {data ? data.total_franchise : "0"}
                        </Card.Title>
                        <Card.Text className="fs-16 fw-medium">
                          No. of Franchise
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 h-100">
                    <Card className="bg-purple text-white">
                      <Card.Body>
                        <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
                          <svg
                            width="29.4"
                            height="26.46"
                            viewBox="0 0 29.4 26.46"
                          >
                            <path
                              id="Icon_material-business-center"
                              data-name="Icon material-business-center"
                              d="M14.76,23.61V22.14H4.485L4.47,28.02a2.93,2.93,0,0,0,2.94,2.94H27.99a2.93,2.93,0,0,0,2.94-2.94V22.14H20.64v1.47Zm14.7-13.23H23.566V7.44L20.626,4.5h-5.88l-2.94,2.94v2.94H5.94A2.949,2.949,0,0,0,3,13.32v4.41a2.93,2.93,0,0,0,2.94,2.94h8.82V17.73h5.88v2.94h8.82a2.949,2.949,0,0,0,2.94-2.94V13.32A2.949,2.949,0,0,0,29.46,10.38Zm-8.82,0H14.76V7.44h5.88Z"
                              transform="translate(-3 -4.5)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <Card.Title className="fs-30 mt-3 mb-2 fw-normal">
                          {data ? data.total_seller : "0"}
                        </Card.Title>
                        <Card.Text className="fs-16 fw-medium">
                          No. of Businesses
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 h-100">
                    <Card className="bgorange text-white">
                      <Card.Body>
                        <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
                          <svg
                            id="Job_search"
                            data-name="Job search"
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.748"
                            height="31.748"
                            viewBox="0 0 31.748 31.748"
                          >
                            <g id="Job_Search-2" data-name="Job_Search">
                              <g
                                id="Group_21747"
                                data-name="Group 21747"
                                transform="translate(0 3.72)"
                              >
                                <path
                                  id="Path_28727"
                                  data-name="Path 28727"
                                  d="M13.825,68.789,8.789,73.825h5.036Z"
                                  transform="translate(-8.244 -68.244)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_28728"
                                  data-name="Path 28728"
                                  d="M19.532,74.882A10.213,10.213,0,0,1,10.431,60H7.441v7.441H0V88.028H20.463V74.835C20.156,74.863,19.846,74.882,19.532,74.882ZM3.72,71.161h7.441v1.86H3.72ZM16.742,84.183H3.72v-1.86H16.742Zm0-3.72H3.72V78.6H16.742Zm0-3.72H3.72v-1.86H16.742Z"
                                  transform="translate(0 -60)"
                                  fill="#fff"
                                />
                              </g>
                              <g
                                id="Group_21748"
                                data-name="Group 21748"
                                transform="translate(11.161)"
                              >
                                <circle
                                  id="Ellipse_18"
                                  data-name="Ellipse 18"
                                  cx="0.93"
                                  cy="0.93"
                                  r="0.93"
                                  transform="translate(7.441 5.581)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_28729"
                                  data-name="Path 28729"
                                  d="M275.581,182.79a2.79,2.79,0,1,0-5.581,0v2.278a7.852,7.852,0,0,0,5.581,0Z"
                                  transform="translate(-264.419 -168.839)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_28730"
                                  data-name="Path 28730"
                                  d="M188.371,0a8.369,8.369,0,0,0-4.651,15.329V13.952A4.656,4.656,0,0,1,188.371,9.3a2.79,2.79,0,1,1,2.79-2.79,2.793,2.793,0,0,1-2.79,2.79,4.656,4.656,0,0,1,4.651,4.651v1.377A8.369,8.369,0,0,0,188.371,0Z"
                                  transform="translate(-180)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <path
                              id="Path_28731"
                              data-name="Path 28731"
                              d="M421.749,240.377a10.236,10.236,0,0,1-1.312,1.319l4.362,4.548,1.315-1.315Z"
                              transform="translate(-394.367 -225.472)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <Card.Title className="fs-30 mt-3 mb-2 fw-normal">
                          {data ? data.total_leads : "0"}
                        </Card.Title>
                        <Card.Text className="fs-16 fw-medium">
                          No. of Leads
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card>
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};
