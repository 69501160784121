import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { FaUserAlt } from "react-icons/fa";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../validations/Profile";
import InputText from "../../components/common/InputText";
import Loader from "../../components/common/Loader";
import { changePassword } from "../../services/userService";
import storage from "../../helpers/storage";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../router/protected";
import { EditEntitiyByTokenMethod } from "../../helpers/helpersMethods";
import { CHANGE_PASSWORD_SUCCESS } from "../../validations/ValidationErrors";

export const ChangePassword = () => {
  // Custom Select handling
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitForm = (value) => {
    EditEntitiyByTokenMethod(
      setLoading,
      changePassword,
      value,
      CHANGE_PASSWORD_SUCCESS,
      `login`,
      navigate
    );
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium fs-18">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <span className="theme-icon-box-inner">
                      <FaUserAlt size={30} />
                    </span>
                  </div>
                  Change Password
                </h5>
              </Stack>
            </div>
            <Formik
              validationSchema={ChangePasswordSchema}
              initialValues={{
                current_password: "",
                new_password: "",
                confirm_password: "",
              }}
              onSubmit={(values, actions) => {
                values.new_password = values.new_password.trim();
                values.current_password = values.current_password.trim();
                values.confirm_password = values.confirm_password.trim();
                const { confirm_password, ...formData } = values;
                submitForm(formData);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <div className="flex-grow-1 d-flex flex-column px-1">
                  <div className="p-3 d-flex flex-column h-100">
                    <div className="d-flex flex-column flex-grow-1 theme-from">
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="mt-3 pb-1">
                          <InputText
                            controlId="current_password"
                            label="Current Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.current_password}
                            touched={touched.current_password}
                            className="w-100 fs-16 mb-3 custom-text-field"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.current_password}
                            name="current_password"
                          />
                        </Col>
                      </Row>
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="pb-1">
                          <InputText
                            controlId="new_password"
                            label="New Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.new_password}
                            touched={touched.new_password}
                            className="w-100 fs-16 mb-3 custom-text-field"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.new_password}
                            name="new_password"
                          />
                        </Col>
                      </Row>
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="pb-1">
                          <InputText
                            controlId="confirm_password"
                            label="Confirm Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.confirm_password}
                            touched={touched.confirm_password}
                            className="w-100 fs-16 custom-text-field mb-2"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.confirm_password}
                            name="confirm_password"
                          />
                        </Col>
                      </Row>

                      <Stack direction="horizontal" gap={2} className="my-3">
                        <Button
                          variant="primary"
                          className="rounded-lg fs-15 mw-84"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Link
                          to={nameBasedProtectedRoutes.dashborad.path}
                          className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn cancelBtn"
                        >
                          Cancel
                        </Link>
                      </Stack>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </Card>
        </div>
      </div>

      <Loader isLoading={loading} />
    </>
  );
};
